<h2 mat-dialog-title>{{ 'REGISTRATIONS.ADD_DIALOG.TITLE' | translate }}</h2>

<mat-dialog-content class="mat-typography">

  <div class="row">
    <div>{{ 'REGISTRATIONS.ADD_DIALOG.APPLICATION' | translate }}:</div>
    <div>{{ subscription.application.name }}</div>
  </div>
  <div class="row">
    <div>{{ 'REGISTRATIONS.ADD_DIALOG.LICENSE_TYPE' | translate }}:</div>
    <div>{{'SUBSCRIPTIONS.TYPES.' + subscription.type | translate}}</div>
  </div>

  <mat-form-field>
    <mat-label>{{'REGISTRATIONS.ADD_DIALOG.USER' | translate}}</mat-label>
    <mat-select [(ngModel)]="userId">
      <mat-option *ngFor="let user of availableUsers" [value]="user.id">
        <ng-container *ngIf="user.name">{{ user.name }} &ndash; {{ user.email }}</ng-container>
        <ng-container *ngIf="!user.name">{{ user.email }}</ng-container>
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="close()">{{ 'GLOBAL.CANCEL' | translate }}</button>
  <button mat-button color="primary" (click)="addUserClicked()">{{'GLOBAL.ADD' | translate }}</button>
</mat-dialog-actions>