import { Injectable } from '@angular/core';

import { SortItem } from '../models/sort-item';
import { FilterItem } from '../models/filter-item';
import { LocalStorageConstants } from '../utils/local-storage-constants';

export type FilterEntityType = 'organisations'

@Injectable({ providedIn: 'root' })
export class FilterService {
  public validFilterKeysMap: Map<FilterEntityType, string[]> = new Map([
    ['organisations', ['applicationId', 'expireDate', 'filterText', 'subscriptionType']]
  ]);

  public constructParametersPaged(filterItems: FilterItem[], sortItems: SortItem[], pageSize: number, pageIndex: number) {
    const pageSizeFilterItem = new FilterItem();
    pageSizeFilterItem.key = 'pageSize';
    pageSizeFilterItem.value = pageSize;
    const offsetFilterItem = new FilterItem();
    offsetFilterItem.key = 'offset';
    offsetFilterItem.value = pageIndex * pageSize;
    const newFilterItems = filterItems.concat([pageSizeFilterItem, offsetFilterItem]);
    return this.constructParameters(newFilterItems, sortItems);
  }

  public getFromStorage(filterEntity: FilterEntityType) {
    const filterItems: FilterItem[] = [];
    const localStorageKey = `${LocalStorageConstants.FILTER_PREFIX}-${filterEntity}`;
    const localStorageData = localStorage.getItem(localStorageKey);

    if (localStorageData) {
      try {
        const parsedStorageData: any[] = JSON.parse(localStorageData);

        for (const item of parsedStorageData) {
          if ('key' in item && this.validFilterKeysMap.get(filterEntity).includes(item.key) && 'value' in item) {
            filterItems.push(item as FilterItem);
          } else {
            console.warn('Invalid object in localStorage: ', item);
          }
        }
      } catch (e) {
        console.error('Error parsing data from localStorage', e);
      }
    }
    return filterItems;
  }

  public setToStorage(filterEntity: FilterEntityType, filterItems: FilterItem[]) {
    const localStorageKey = `${LocalStorageConstants.FILTER_PREFIX}-${filterEntity}`;
    try {
      const validFilterItems = filterItems.filter(i => this.validFilterKeysMap.get(filterEntity).includes(i.key) && i.value);
      localStorage.setItem(localStorageKey, JSON.stringify(validFilterItems));
    } catch (e) {
      console.error('Error saving data to localStorage', e);
    }
  }

  public constructParameters(filterItems: FilterItem[], sortItems: SortItem[]) {
    const parameters = [];

    if (filterItems.length > 0) {
      parameters.push(this.filterToString(filterItems));
    }

    const isSorted = sortItems.some(i => i.selected);
    if (isSorted) {
      parameters.push(this.sortToString(sortItems));
    }

    return parameters.join('&');
  }

  private filterToString(filterItems: FilterItem[]): string {
    const filter: string[] = [];
    for (const filterItem of filterItems) {
      if (filterItem.value instanceof Date) {
        filter.push(this.encodeFilterParameter(filterItem.key, filterItem.value.toISOString()));
      } else if (filterItem.value instanceof Array) {
        filterItem.value.forEach(v => {
          filter.push(this.encodeFilterParameter(filterItem.key, v));
        });
      } else {
        filter.push(this.encodeFilterParameter(filterItem.key, filterItem.value));
      }
    }
    return filter.join('&');
  }

  private encodeFilterParameter(key: string, value: string | number): string {
    return `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`;
  }

  private sortToString(sortItems: SortItem[]): string {
    const selectedItem = sortItems.find(i => i.selected === true);
    if (sortItems.length > 0 && selectedItem) {
      return `sortKey=${selectedItem.urlParameter}&sortOrder=${selectedItem.direction}`;
    }
  }

}
