import { AfterViewInit, Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as qrcode from 'qrcode';
import { AppContext } from 'src/app/helpers/app-context';
import { UsersHttpService } from 'src/app/services/http/users-http.service';
import { MessageService } from 'src/app/services/message.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-enable-mfa-dialog',
  templateUrl: './enable-mfa-dialog.component.html',
  styleUrls: ['./enable-mfa-dialog.component.scss']
})
export class EnableMfaDialogComponent implements AfterViewInit {

  public readonly MFA_ISSUER = 'OmniTRANS next';
  public readonly MFA_ENABLED_DIALOG_WIDTH = '500px';

  public secretBase32Encoded = this.generateSecret();
  public verificationCode = '';
  public recoveryCodes: string[] = [];

  @ViewChild('qrCodeCanvas', { static: true }) private qrCodeCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('recoveryCodesMessageBox', { static: true }) private recoveryCodesMessageBox: TemplateRef<unknown>;

  constructor(
    private dialogRef: MatDialogRef<EnableMfaDialogComponent>,
    private usersHttpService: UsersHttpService,
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private appContext: AppContext
  ) {
  }

  public ngAfterViewInit() {
    qrcode.toCanvas(this.qrCodeCanvas.nativeElement, this.generateQrCodeUrl());
  }

  public close() {
    this.dialogRef.close(false);
  }

  public enableMultiFactorAuthentication() {
    this.usersHttpService.enableMultiFactorAuthentication(this.appContext.user.id, {
      secretBase32Encoded: this.secretBase32Encoded,
      verificationCode: this.verificationCode
    })
      .pipe(this.spinnerService.register())
      .subscribe(response => {
        if (response.verificationCodeAccepted) {
          this.recoveryCodes = response.recoveryCodes;
          this.messageService.showOkDialog({
            templateRef: this.recoveryCodesMessageBox,
            titleLangKey: 'MFA.ENABLE_DIALOG.SUCCESS_TITLE'
          }, this.MFA_ENABLED_DIALOG_WIDTH, () => this.dialogRef.close(true));
        } else {
          this.messageService.showOkDialog({
            titleLangKey: 'MFA.ENABLE_DIALOG.FAILED_TITLE',
            messageLangKey: 'MFA.ENABLE_DIALOG.FAILED_TEXT'
          }, undefined, () => this.verificationCode = '');
        }
      });
  }

  private generateSecret() {
    const secretBytes = new Uint8Array(16);
    crypto.getRandomValues(secretBytes);
    return this.toBase32String(secretBytes);
  }

  private toBase32String(bytes: Uint8Array) {
    const base32Chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567';
    let base32String = '';
    let bits = 0;
    let value = 0;
    for (const byte of bytes) {
      value = (value << 8) | byte;
      bits += 8;
      while (bits >= 5) {
        base32String += base32Chars[(value >>> (bits - 5)) & 31];
        bits -= 5;
      }
    }
    if (bits > 0) {
      base32String += base32Chars[(value << (5 - bits)) & 31];
    }
    return base32String;
  }

  private generateQrCodeUrl() {
    const issuer = encodeURIComponent(this.MFA_ISSUER);
    const label = encodeURIComponent(this.MFA_ISSUER + ':' + this.appContext.user.email);
    return `otpauth://totp/${label}?secret=${this.secretBase32Encoded}&issuer=${issuer}`;
  }


}
