import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppContext } from 'src/app/helpers/app-context';
import { AccountsRole } from 'src/app/models/accounts-role';
import { User } from 'src/app/models/user';
import { AuthorisationService } from 'src/app/services/authorisation.service';
import { LanguageUtils } from 'src/app/utils/language-utils';
import { UserUtils } from 'src/app/utils/user-utils';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss']
})
export class HeaderComponent {

  public readonly LANGUAGES: string[] = LanguageUtils.SUPPORTED_LANGUAGES;

  @Input() public pageLabel: string;
  @Input() public statusIcon: string = null;

  public showOrganisations: boolean = false;
  public showMyOrganisation: boolean = false;

  public showLogo: boolean = true;
  public showUsername: boolean = true;

  public currentLanguage: string;
  public currentUser: User;

  constructor(
    public router: Router,
    public appContext: AppContext,
    private authorisationService: AuthorisationService,
    private translateService: TranslateService,
    breakpointObserver: BreakpointObserver
  ) {
    this.currentLanguage = translateService.currentLang;
    this.currentUser = this.appContext.user;
    this.showOrganisations = this.currentUser.accountsRole === AccountsRole.SUPPORT;
    this.showMyOrganisation = this.currentUser.accountsRole === AccountsRole.SUPPORT || this.currentUser.accountsRole === AccountsRole.ORGANISATION_MANAGER;

    breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Web]).subscribe(result => {
      this.showLogo = !breakpointObserver.isMatched(Breakpoints.Handset);
      this.showUsername = breakpointObserver.isMatched(Breakpoints.Web);
    });
  }

  public onLogoutClick() {
    this.authorisationService.logout();
  }

  public onLanguageClick(language: string) {
    this.currentLanguage = language;
    LanguageUtils.changeLanguage(this.translateService, language);
  }

  public getUserMediaImageUrl(user: User) {
    return UserUtils.getUserMediaImageUrl(user);
  }

}
