<mat-toolbar class="toolbar">
  <mat-toolbar-row class="toolbar-row">
    <a class="hamburger" mat-icon-button [matMenuTriggerFor]="appMenu">
      <mat-icon>menu</mat-icon>
    </a>

    <mat-menu #appMenu="matMenu">
      <a *ngIf="showOrganisations" mat-menu-item routerLink="/organisations">
        <span>{{'GLOBAL.ORGANISATIONS' | translate}}</span>
      </a>
      <a *ngIf="showMyOrganisation" mat-menu-item [routerLink]="['/organisations', currentUser.organisationId]">
        <span>{{'GLOBAL.MANAGE_MY_ORGANISATION' | translate}}</span>
      </a>
      <a mat-menu-item routerLink="/account">
        <span>{{'GLOBAL.MY_ACCOUNT' | translate}}</span>
      </a>
    </mat-menu>

    <div class="logo" *ngIf="showLogo"><img src="/assets/headerlogo_wit.svg" routerLink="/" /></div>

    <span class="page-name">{{pageLabel}}</span>

    <mat-icon class="status-icon" *ngIf="statusIcon">{{ statusIcon }}</mat-icon>

    <span class="spacer"></span>

    <span class="user-name" *ngIf="showUsername">{{ appContext.user?.name || appContext.user?.email }}</span>

    <div *ngIf="appContext.user" class="profile">
      <span class="menu" mat-icon-button [matMenuTriggerFor]="userMenu">
        <img src="{{ getUserMediaImageUrl(appContext.user) }}">
      </span>
    </div>

    <app-app-drawer></app-app-drawer>

    <mat-menu #userMenu="matMenu" xPosition="before">
      <button mat-menu-item *ngIf="false">{{ 'HEADER.USER.PROFILE' | translate }}</button>
      <button mat-menu-item *ngIf="false">{{ 'HEADER.USER.SETTINGS' | translate }}</button>
      <button mat-menu-item *ngIf="false">{{ 'HEADER.USER.HELP' | translate }}</button>
      <button mat-menu-item [matMenuTriggerFor]="languageSubMenu">{{ 'HEADER.USER.LANGUAGE' | translate }}</button>
      <mat-menu #languageSubMenu="matMenu">
        <button mat-menu-item *ngFor="let language of LANGUAGES" (click)="onLanguageClick(language)">
          <mat-icon>{{ currentLanguage === language ? 'radio_button_checked' : 'radio_button_unchecked' }}</mat-icon>
          <span><img src="/assets/i18n/{{ language }}.png">&nbsp;
            {{ 'HEADER.USER.LANGUAGE.' + language.toUpperCase() | translate }}</span>
        </button>
      </mat-menu>
      <button mat-menu-item (click)="onLogoutClick()">{{ 'HEADER.USER.LOGOUT' | translate }}</button>
    </mat-menu>

  </mat-toolbar-row>
</mat-toolbar>
