import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: InputDialogData) {
  }

}

export interface InputDialogData {
  value: string;
  titleLangKey: string;
  messageLangKey: string;
  placeholderLangKey: string;
  hintLangKey: string;
  langParams: any;
}
