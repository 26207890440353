import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Organisation } from 'src/app/models/organisation';
import { OrganisationsHttpService } from 'src/app/services/http/organisations-http.service';
import { MessageService } from 'src/app/services/message.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent {

  @Input() organisation: Organisation;
  @Output() refresh = new EventEmitter();

  constructor(
    private organisationsHttpService: OrganisationsHttpService,
    private spinnerService: SpinnerService,
    private messageService: MessageService
  ) { }

  public onDeletePictureClicked() {
    this.messageService.showOkCancelDialog('ORGANISATIONS.MANAGEMENT.MEDIA.CONFIRM_DELETE_PICTURE', null, () => {
      this.organisationsHttpService.deleteOrganisationImage(this.organisation.id)
        .pipe(this.spinnerService.register())
        .subscribe(
          () => {
            this.organisation.hasAvatar = false;
            this.refresh.emit();
          },
          (error) => {
            this.messageService.showErrorSnackBar('ORGANISATIONS.MANAGEMENT.MEDIA.ALERT_PICTURE_DELETE_ERROR');
          }
        );
    });
  }

  public onFileUploaded() {
    this.organisation.hasAvatar = true;
    this.refresh.emit();
  }

}
