/**
 * Utilities for validating and converting dates
 */
export class DateTimeUtils {

  private static readonly REGEX_ISO8601_FORMAT: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

  /**
   * Whether a string is in ISO8601 date time format (Zulu time), which can be parsed by the Date object constructor.
   */
  public static isIso8601DateString(value: string) {
    return DateTimeUtils.REGEX_ISO8601_FORMAT.test(value);
  }
}
