import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppContext } from '../helpers/app-context';
import { GeneralConstants } from '../utils/general-constants';
import { AuthorisationHttpService } from './http/authorisation-http.service';

/**
 * Service for authorisation.
 */
@Injectable({ providedIn: 'root' })
export class AuthorisationService {

  constructor(
    private authenticationHttpService: AuthorisationHttpService,
    private translateService: TranslateService,
    private appContext: AppContext) {
  }

  /**
   * Should be called when entering the webapp. Best to do so in a route guard, because it will prevent execution of the webapp
   * until the guard finishes (and thus logging in is handled). Guard all routes, because these are all entry points to the webapp.
   *
   * It gets user information from the server for the currently logged in user (session), which is known via a httpOnly cookie.
   * If no such session exists, user=null returned, and a redirect to the login page will happen, which will eventually
   * set the cookie and return to the webapp, at which point it will come back here. This time we will be
   * able to fetch the user properly.
   */
  public validateAuthAndLoginIfNeeded(returnPath: string): Observable<boolean> {
    if (this.appContext.user) {
      // In-site navigation, user still known
      return of(true);
    } else {
      return this.authenticationHttpService.getBootstrapInfo().pipe(
        map(bootstrapInfo => {
          this.appContext.user = bootstrapInfo.currentUser;
          if (!this.appContext.user) {
            this.login(returnPath);
          }

          return !!this.appContext.user;
        })
      );
    }
  }

  public login(returnPath: string) {
    this.redirectToOAuthAuthorizeEndpoint(returnPath);
  }

  public logout() {
    this.redirectToOAuthLogoutEndpoint();
  }

  private redirectToOAuthAuthorizeEndpoint(returnPath: string) {
    const params = (new HttpParams())
      .set('returnPath', returnPath)
      .set('locale', this.translateService.currentLang)
      .toString();

    const loginUrl = `${GeneralConstants.API_PREFIX}/authorisation/login?${params}`;
    location.href = loginUrl;
  }

  private redirectToOAuthLogoutEndpoint() {
    const params = (new HttpParams())
      .set('locale', this.translateService.currentLang)
      .toString();

    const logoutUrl = `${GeneralConstants.API_PREFIX}/authorisation/logout?${params}`;
    location.href = logoutUrl;
  }

}
