<div class="form-container">
  <input type="file" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|images/*" #file style="display:none"
    [attr.disabled]="uploadStatus === 1 || uploadStatus === 2 ? '' : null" (change)="onFileSelected($event)">

  <div [ngClass]="{'dropzone': !dropzoneHover, 'dropzone-hover': dropzoneHover}" *ngIf="!fileSelected"
    fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="24px" id="drop_zone" (drop)="dropHandler($event)"
    (click)="openFileDialog()" (dragover)="dragOverHandler($event)" (dragleave)="dragLeaveHandler()">
    <div class="dropzone-text mat-typography">{{ 'ORGANISATIONS.MANAGEMENT.MEDIA.TEXT_DROPBOX' | translate }}</div>
    <div class="dropzone-button">
      <button mat-raised-button color="accent">{{ 'ORGANISATIONS.MANAGEMENT.MEDIA.BUTTON_CHOOSE_FILE' | translate }}</button>
    </div>
  </div>

  <mat-list>
    <div class="selectedFileName mat-typography">{{selectedFileName}}</div>
    <mat-progress-bar *ngIf="uploadStatus === 1 || uploadStatus === 2" class="example-margin" mode="indeterminate">
    </mat-progress-bar>
  </mat-list>

</div>