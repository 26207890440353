<h2 mat-dialog-title *ngIf="data.titleLangKey">{{ data.titleLangKey | translate:data.langParams }}</h2>
<div mat-dialog-content class="mat-typography">
  <p *ngIf="data.messageLangKey">{{ data.messageLangKey | translate:data.langParams }}</p>
  <p *ngIf="data.templateRef">
    <ng-container *ngTemplateOutlet="data.templateRef"></ng-container>
  </p>
</div>
<div mat-dialog-actions class="buttons">
  <button *ngFor="let button of data.buttons" mat-button [mat-dialog-close]="button"
    [color]="button === data.primaryButton ? 'primary' : ''"
    [attr.cdkFocusInitial]="button === data.primaryButton">{{'GLOBAL.' + button | translate}}</button>
</div>
