<div class="container equal-columns" [class.inactive]="!multiSelectActive" [class.max-columns-2]="maxColumns === 2"
  [class.max-columns-3]="maxColumns === 3">

  <mat-button-toggle class="multi-button" [checked]="multiSelectActive" (change)="onMultiSelectActiveChange($event)"
    matTooltip="{{ 'ORGANISATIONS.MULTI_SELECT_TOOLBAR.ACTIVATE_TOOLTIP' | translate }}">
    <mat-icon>library_add_check</mat-icon> {{ 'ORGANISATIONS.MULTI_SELECT_TOOLBAR.ACTIVATE' | translate }}
  </mat-button-toggle>

  <mat-button-toggle #showSelectedActiveButton
    [disabled]="!multiSelectActive || (selection.selected.length === 0 && !showSelectedActive)"
    [checked]="showSelectedActive" (change)="onShowSelectedActiveChange($event)"
    matTooltip="{{ 'ORGANISATIONS.MULTI_SELECT_TOOLBAR.FILTER_TOOLTIP' | translate }}">
    <mat-icon>filter_alt</mat-icon>
    <span>{{ 'ORGANISATIONS.MULTI_SELECT_TOOLBAR.FILTER' | translate }} ({{selection.selected.length}})</span>
  </mat-button-toggle>

  <button mat-stroked-button matTooltip="{{'ORGANISATIONS.MULTI_SELECT_TOOLBAR.EDIT_EXPIREDATE_TOOLTIP' | translate}}"
    cdkOverlayOrigin #stateTrigger="cdkOverlayOrigin"
    [disabled]="!multiSelectActive || !showSelectedActive || selection.selected.length === 0 || !selectedApplicationId"
    (click)="onMultiChangeExpireDateClick()">
    <mat-icon>today</mat-icon> {{'ORGANISATIONS.MULTI_SELECT_TOOLBAR.EDIT_EXPIREDATE' | translate}}
  </button>

</div>