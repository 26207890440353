<app-main-page-layout pageLabel="{{'GLOBAL.ORGANISATION' | translate}} {{organisation?.name}}" [footer]="true"
  [footerFixed]="true">

  <mat-tab-group (selectedTabChange)="animatingTabs = true" (animationDone)="animatingTabs = false" [selectedIndex]="1">

    <mat-tab #infoTab label="{{ 'ORGANISATIONS.MANAGEMENT.INFOTAB.TITLE' | translate }}">
      <div class="tab">
        <app-information [organisation]="organisation"></app-information>
        <app-media [organisation]="organisation" (refresh)="refresh()"></app-media>
      </div>
    </mat-tab>

    <mat-tab #usersTab label="{{ 'ORGANISATIONS.MANAGEMENT.USERSTAB.TITLE' | translate }}">
      <div class="tab">
        <app-users [organisation]="organisation" (refresh)="refresh()"></app-users>
      </div>
    </mat-tab>

    <mat-tab #subscriptionsTab label="{{ 'ORGANISATIONS.MANAGEMENT.SUBSCRIPTIONSTAB.TITLE' | translate }}">
      <div class="tab">
        <app-subscriptions [organisation]="organisation" (refresh)="refresh()"></app-subscriptions>
      </div>
    </mat-tab>
  </mat-tab-group>

  <button *ngIf="organisation && usersTab.isActive && organisation && !animatingTabs" footerFloater mat-fab
    class="add-button" (click)="onAddUserClick()" color="accent">
    <mat-icon>add</mat-icon>
  </button>
  <button *ngIf="currentUserHasSupportRole && subscriptionsTab.isActive && organisation && !animatingTabs" footerFloater
    mat-fab class="add-button" (click)="onAddLicenseClick()" color="accent">
    <mat-icon>add</mat-icon>
  </button>

  <app-page-spinner></app-page-spinner>

</app-main-page-layout>
