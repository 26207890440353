import { _isNumberValue } from '@angular/cdk/coercion';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Application } from 'src/app/models/application';
import { ApplicationRegistration } from 'src/app/models/application-registration';
import { ApplicationSubscription } from 'src/app/models/application-subscription';
import { User } from 'src/app/models/user';
import { UsersHttpService } from 'src/app/services/http/users-http.service';
import { MessageService } from 'src/app/services/message.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UserUtils } from 'src/app/utils/user-utils';
import { RegistrationAddDialogData } from './registration-add-dialog/registration-add-dialog-data';
import { RegistrationAddDialogComponent } from './registration-add-dialog/registration-add-dialog.component';

@Component({
  selector: 'app-registrations',
  templateUrl: './registrations.component.html',
  styleUrls: ['./registrations.component.scss']
})
export class RegistrationsComponent implements AfterViewInit {

  constructor(
    private usersHttpService: UsersHttpService,
    private translateService: TranslateService,
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private dialog: MatDialog
  ) { }

  private static readonly DEFAULT_COLUMNS = ['name', 'email', 'actions'];
  public displayedColumns: string[] = RegistrationsComponent.DEFAULT_COLUMNS;
  public dataSource: MatTableDataSource<User> = new MatTableDataSource([]);
  @ViewChild(MatSort) sort: MatSort;

  @Output() refresh = new EventEmitter();

  public application: Application;
  private _subscription: ApplicationSubscription;

  get subscription() {
    return this._subscription;
  }

  @Input() set subscription(subscription: ApplicationSubscription) {
    this._subscription = subscription;
    this.application = this.subscription.application;
    this.setRegisteredUsers();
  }
  private _users: User[];

  get users() {
    return this._users;
  }

  @Input() set users(users: User[]) {
    this._users = users;
    this.setRegisteredUsers();
  }

  private _registeredUsers: User[];

  get registeredUsers() {
    return this._registeredUsers;
  }

  private setRegisteredUsers() {
    if (this.users && this.application) {
      this._registeredUsers = this.users.filter((user: User) =>
        user.registrations.findIndex((registration: ApplicationRegistration) =>
          registration.application.id === this.application.id) !== -1
      );
      this.dataSource = new MatTableDataSource(this.registeredUsers);
      this.initializeSortingForDataTable();
    }
  }

  public ngAfterViewInit(): void {
    this.initializeSortingForDataTable();
  }

  private initializeSortingForDataTable() {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item: any, property) => {
      const value = item[property];
      return _isNumberValue(value) ? Number(value).toString() : value;
    };
  }

  public onAddRegistrationClicked() {
    const alreadyRegisteredUserIds = this.registeredUsers.map(user => user.id);
    const availableUsers = this.users.filter(user => !alreadyRegisteredUserIds.includes(user.id));
    if (availableUsers.length > 0) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      const dialogData: RegistrationAddDialogData = {
        availableUsers,
        subscription: this.subscription
      };
      dialogConfig.data = dialogData;
      dialogConfig.width = '500px';
      const dialogRef = this.dialog.open(RegistrationAddDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(ok => ok && this.refresh.emit());
    } else {
      this.messageService.showInfoSnackBar(
        this.translateService.instant('REGISTRATIONS.NO_UNREGISTRED_USERS_MESSAGE'));
    }
  }

  public onRemoveRegistrationClicked(user: User) {
    this.messageService.showOkCancelDialog({
      titleLangKey: 'REGISTRATIONS.LABEL_ACTION_DELETE',
      messageLangKey: user.name ? 'REGISTRATIONS.DELETE_CONFIRM_WITH_NAME' : 'REGISTRATIONS.DELETE_CONFIRM',
      langParams: { email: user.email, name: user.name, license: this.application.name }
    }, null, () => {
      this.usersHttpService.deleteRegistrationForUser(user.id, this.application.id)
        .pipe(this.spinnerService.register())
        .subscribe((ok) => ok && this.refresh.emit());
    });
  }

  public getUserMediaImageUrl(user: User) {
    return UserUtils.getUserMediaImageUrl(user);
  }

}
