<mat-card>
    <mat-card-header>
      <mat-card-title>{{ 'ORGANISATIONS.MANAGEMENT.MEDIA.TITLE' | translate }}</mat-card-title>
      <div class="white-space"></div>
      <button *ngIf="!organisation || organisation?.hasAvatar" class="delete-button" (click)="onDeletePictureClicked()"
        matTooltip="{{ 'ORGANISATIONS.MANAGEMENT.MEDIA.TOOLTIP_DELETE_PICTURE' | translate }}" mat-icon-button>
        <mat-icon>delete</mat-icon>
      </button>
    </mat-card-header>
    <mat-card-content>
      <div class="image-upload" *ngIf="organisation && !organisation?.hasAvatar">
        <app-image-upload [uploadUrl]="'/organisations/' + organisation?.id + '/media'" (fileUploaded)="onFileUploaded()">
        </app-image-upload>
      </div>
      <div *ngIf="organisation?.hasAvatar">
        <div class="image">
          <img
            src="{{'/api/v1/organisations/' + organisation?.id + '/media'}}" />
        </div>
      </div>
    </mat-card-content>
  </mat-card>
