import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AppContext } from 'src/app/helpers/app-context';
import { User } from 'src/app/models/user';
import { AuthorisationHttpService } from 'src/app/services/http/authorisation-http.service';
import { OrganisationsHttpService } from 'src/app/services/http/organisations-http.service';
import { UsersHttpService } from 'src/app/services/http/users-http.service';
import { MessageService } from 'src/app/services/message.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UserUtils } from 'src/app/utils/user-utils';
import { EnableMfaDialogComponent } from './enable-mfa-dialog/enable-mfa-dialog.component';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {

  public readonly ENABLE_MFA_DIALOG_WIDTH = '475px';
  public readonly DISABLE_MFA_DIALOG_WIDTH = '400px';

  public user: User;
  public avatarUrl: string;
  public organisationName: string;

  constructor(
    private organisationsHttpService: OrganisationsHttpService,
    private usersHttpService: UsersHttpService,
    private authorisationHttpService: AuthorisationHttpService,
    private messageService: MessageService,
    private spinnerService: SpinnerService,
    private dialog: MatDialog,
    private appContext: AppContext
  ) {
    this.user = appContext.user;
    this.avatarUrl = UserUtils.getUserMediaImageUrl(this.user);

    this.organisationsHttpService.getOrganisationName(this.user.organisationId)
      .subscribe(organisation => this.organisationName = organisation.name);
  }

  public enableMultiFactorAuthentication() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = this.ENABLE_MFA_DIALOG_WIDTH;
    const dialogRef = this.dialog.open(EnableMfaDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.refreshBootstrapInfoForMfaState();
      }
    });
  }

  public disableMultiFactorAuthentication() {
    this.messageService.showInputDialog('', {
      titleLangKey: 'MFA.DISABLE_DIALOG.TITLE',
      messageLangKey: 'MFA.DISABLE_DIALOG.TEXT',
      placeholderLangKey: 'MFA.DISABLE_DIALOG.VERIFICATION_CODE'
    }, this.DISABLE_MFA_DIALOG_WIDTH, verificationCode => {
      this.usersHttpService.disableMultiFactorAuthentication(this.user.id, verificationCode)
        .pipe(this.spinnerService.register())
        .subscribe({
          next: () => this.refreshBootstrapInfoForMfaState(),
          error: () => this.messageService.showOkDialog({
            titleLangKey: 'MFA.DISABLE_DIALOG.FAILED_TITLE',
            messageLangKey: 'MFA.DISABLE_DIALOG.FAILED_TEXT'
          })
        });
    });
  }

  private refreshBootstrapInfoForMfaState() {
    this.authorisationHttpService.getBootstrapInfo()
      .pipe(this.spinnerService.register())
      .subscribe(bootstrapInfo => {
        this.appContext.user = bootstrapInfo.currentUser;
        this.user = this.appContext.user;
      });
  }
}
