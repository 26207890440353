import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, OperatorFunction } from 'rxjs';
import { GeneralConstants } from '../../utils/general-constants';
import { DateTimeUtils } from '../../utils/datetime-utils';
import { map } from 'rxjs/operators';

export class BaseHttpService {

  constructor(protected httpClient: HttpClient) {
  }

  protected get<T>(url: string): Observable<T> {
    return this.httpClient.get<T>(this.expandUrl(url));
  }

  protected getUrlAsBlob<Blob>(url: string): Observable<Blob> {
    return this.httpClient.get<Blob>(this.expandUrl(url), { responseType: 'blob' as 'json' });
  }

  protected getWithResponse<T>(url: string): Observable<HttpResponse<T>> {
    return this.httpClient.get<T>(this.expandUrl(url), { observe: 'response' });
  }

  protected post(url: string, body: any | null): Observable<any> {
    return this.httpClient.post(this.expandUrl(url), body);
  }

  protected postT<T>(url: string, body: any | null): Observable<T> {
    return this.httpClient.post<T>(this.expandUrl(url), body);
  }

  protected put(url: string, body: any | null): Observable<any> {
    return this.httpClient.put(this.expandUrl(url), body);
  }

  protected putT<T>(url: string, body: any | null): Observable<T> {
    return this.httpClient.put<T>(this.expandUrl(url), body)
  }

  protected delete(url: string): Observable<any> {
    return this.httpClient.delete(this.expandUrl(url));
  }

  /**
   * Takes an object and recursively searches for all strings that should be converted to Date objects.
   */
  protected mapDates<T>(): OperatorFunction<T, T> {
    return map((object: T) => this.transformDatesRecursively(object) as T);
  }

  /**
   * Version of {@link #transformDates} that works for calls that return a HttpResponse.
   */
  protected mapDatesHttpResponse<T>(): OperatorFunction<HttpResponse<T>, HttpResponse<T>> {
    return map((response: HttpResponse<T>) => response.clone({ body: this.transformDatesRecursively(response.body) }));
  }

  private transformDatesRecursively(object: any): any {
    if (object !== null) {
      if (typeof object === 'string' && DateTimeUtils.isIso8601DateString(object)) {
        object = new Date(object);
      } else if (typeof object === 'object') {
        for (const key of Object.keys(object)) {
          object[key] = this.transformDatesRecursively(object[key]);
        }
      }
    }
    return object;
  }

  private expandUrl(url: string): string {
    if (url.length > 0 && (url.lastIndexOf('http:', 0) === 0 || url.lastIndexOf('https:', 0) === 0)) {
      return url;
    } else {
      return GeneralConstants.API_PREFIX + '/' + url;
    }
  }
}
