import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthorisationService } from '../services/authorisation.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private authorisationService: AuthorisationService) {
  }

  public canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authorisationService.validateAuthAndLoginIfNeeded(state.url);
  }

}