<div class="equal-columns" [class.max-columns-1]="maxColumns === 1" [class.max-columns-2]="maxColumns === 2">

  <div class="expireDate" *ngIf="filterExpireDate">
    <mat-form-field>
      <mat-label>{{'FILTER.EXPIREDATE' | translate}}</mat-label>
      <input matInput [matDatepicker]="expireDatePicker" (dateChange)="onFilterChange('expireDate', $event.value)"
        #expireDate [(ngModel)]="selectedExpireDate" [disabled]="filterIsDisabled">
      <mat-datepicker-toggle matSuffix [for]="expireDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #expireDatePicker></mat-datepicker>
    </mat-form-field>
  </div>

  <div class="application" *ngIf="filterApplication">
    <mat-form-field>
      <mat-label>{{'FILTER.APPLICATION' | translate}}</mat-label>
      <mat-select (selectionChange)="onFilterChange('applicationId', $event.value?.id)"
        [(ngModel)]="selectedApplication" [disabled]="filterIsDisabled">
        <mat-option *ngFor="let application of applications" [value]="application">{{application.name}}</mat-option>
      </mat-select>

    </mat-form-field>
  </div>

  <div class="subscriptionType" *ngIf="filterSubscriptionType">
    <mat-form-field>
      <mat-label>{{'FILTER.LICENCE_TYPE' | translate}}</mat-label>
      <mat-select (selectionChange)="onFilterChange('subscriptionType', $event.value)"
        [(ngModel)]="selectedSubscriptionType" [disabled]="filterIsDisabled">
        <mat-option *ngFor="let subscriptionType of subscriptionTypes" [value]="subscriptionType">{{subscriptionType}}
        </mat-option>
      </mat-select>

    </mat-form-field>
  </div>

  <div class="search-form">
    <mat-form-field *ngIf="filterText">
      <input matInput placeholder="{{'FILTER.TEXT_FIELD' | translate}}"
        (change)="onFilterChange('filterText', $any($event.target).value)" #filterText [(ngModel)]="searchText"
        [disabled]="filterIsDisabled">
    </mat-form-field>

    <button mat-icon-button [disabled]="filterItems.length === 0" (click)="onFilterReset()"
      matTooltip="{{'FILTER.CLEAR' | translate}}" [disabled]="filterIsDisabled">
      <mat-icon class="reset">clear</mat-icon>
    </button>
  </div>

</div>
