import { AfterContentInit, ContentChildren, Directive, HostBinding, Input, OnChanges, QueryList, SimpleChanges } from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'a[routerAnchorDisabled],area[routerAnchorDisabled]'
})
export class RouterAnchorDisabledDirective implements OnChanges, AfterContentInit {

  @Input() public routerAnchorDisabled: boolean;

  @HostBinding('attr.disabled') public disabledAttr: string;
  @HostBinding('style.pointer-events') public pointerEventsAttr: string;

  @ContentChildren(RouterLinkWithHref, { descendants: true }) private linksWithHrefs: QueryList<RouterLinkWithHref>;

  public ngAfterContentInit(): void {
    this.linksWithHrefs.changes.subscribe(_ => this.update());
    this.update();
  }

  public ngOnChanges(_: SimpleChanges): void {
    this.update();
  }

  private update() {
    this.disabledAttr = this.routerAnchorDisabled ? 'true' : null;
    this.pointerEventsAttr = this.routerAnchorDisabled ? 'none' : null;
  }

}
