<h2 mat-dialog-title>{{ 'ORGANISATIONS.ADD_DIALOG.TITLE' | translate }}</h2>

<mat-dialog-content class="mat-typography">

  <form class="form-container" [formGroup]="form">

    <!-- Name -->
    <mat-form-field>
      <mat-label>{{ 'ORGANISATIONS.ADD_DIALOG.NAME' | translate }}</mat-label>
      <input required matInput minlength="3" formControlName="name">
      <mat-error *ngIf="form.controls.name.invalid">{{ 'ORGANISATIONS.ADD_DIALOG.ERROR.NAME_INVALID' | translate }}
      </mat-error>
    </mat-form-field>

    <!-- Domain filter -->
    <mat-form-field>
      <mat-label>{{ 'ORGANISATIONS.ADD_DIALOG.DOMAIN_FILTER' | translate }}</mat-label>
      <input matInput formControlName="domainFilter" pattern="^([a-z0-9.-]+\.+[a-z]+)+$">
      <mat-error *ngIf="form.controls.domainFilter.invalid">
        {{ 'ORGANISATIONS.ADD_DIALOG.ERROR.DOMAIN_INVALID' | translate }}
      </mat-error>
    </mat-form-field>

  </form>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="close()">{{ 'GLOBAL.CANCEL' | translate }}</button>
  <button mat-button (click)="save()" [disabled]="form.invalid" color="primary">{{ 'GLOBAL.SAVE' | translate }}</button>
</mat-dialog-actions>