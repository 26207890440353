import { ApplicationSubscription } from './application-subscription';
import { User } from './user';

export class Organisation {
  id: string;
  name: string;
  hasAvatar: boolean;
  subscriptions: ApplicationSubscription[];
  domainFilter: string;
  users: User[];
}
