<h2 mat-dialog-title *ngIf="data.titleLangKey">{{ data.titleLangKey | translate:data.langParams }}</h2>
<div mat-dialog-content class="mat-typography">
  <p *ngIf="data.messageLangKey">{{ data.messageLangKey | translate:data.langParams }}</p>
  <mat-form-field>
    <input matInput [(ngModel)]="data.value" autocomplete="off" cdkFocusInitial
      [placeholder]="data.placeholderLangKey ? (data.placeholderLangKey | translate:data.langParams) : ''" />
    <mat-hint *ngIf="data.hintLangKey">{{ data.hintLangKey | translate:data.langParams }}</mat-hint>
  </mat-form-field>
</div>
<div mat-dialog-actions class="buttons">
  <button mat-button [mat-dialog-close]="data.value"
    [disabled]="data.value?.trim().length === 0" color="primary">{{'GLOBAL.OK' | translate}}</button>
  <button mat-button [mat-dialog-close]="null">{{'GLOBAL.CANCEL' | translate}}</button>
</div>
