import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Application } from 'src/app/models/application';
import { ApplicationSubscription } from 'src/app/models/application-subscription';
import { Organisation } from 'src/app/models/organisation';
import { SubscriptionType } from 'src/app/models/subscription-type';
import { OrganisationsHttpService } from 'src/app/services/http/organisations-http.service';
import { UsersHttpService } from 'src/app/services/http/users-http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { SubscriptionDialogData } from './subscription-dialog-data';
import { SubscriptionDialogType } from './subscription-dialog-type';

@Component({
  selector: 'app-subscription-dialog',
  templateUrl: './subscription-dialog.component.html',
  styleUrls: ['./subscription-dialog.component.scss']
})
export class SubscriptionDialogComponent {

  public readonly SUBSCRIPTION_TYPES: string[] = Object.keys(SubscriptionType).filter(type => type !== SubscriptionType.CORPORATE);

  public type: SubscriptionDialogType;

  public subscription: ApplicationSubscription;
  public organisation: Organisation;

  public applicationId: string;

  public form: FormGroup;

  public availableApplications: Application[];

  constructor(
    private dialogRef: MatDialogRef<SubscriptionDialogComponent>,
    private spinnerService: SpinnerService,
    private organisationsHttpService: OrganisationsHttpService,
    private translateService: TranslateService,
    private usersHttpService: UsersHttpService,
    @Inject(MAT_DIALOG_DATA) public data: SubscriptionDialogData
  ) {
    this.type = data.type;
    this.availableApplications = data.availableApplications;
    this.organisation = data.organisation;
    this.subscription = (this.type === SubscriptionDialogType.ADD) ?
      this.getDefaultSubscription() : this.getCopyOfSubscription(data.subscription);
    this.applicationId = this.subscription.application.id;

    this.form = new FormGroup({
      expireDate: new FormControl(this.subscription.expireDate),
      maxNumberOfRegistrations: new FormControl(this.subscription.maxNumberOfRegistrations, {
        validators: [Validators.min(this.type === SubscriptionDialogType.EDIT ? this.subscription.numberOfRegistrations : 0)]
      })
    });
    this.onSubscriptionTypeChange();
  }

  public save() {
    this.subscription.expireDate = this.form.controls.expireDate.value;
    this.subscription.maxNumberOfRegistrations = this.form.controls.maxNumberOfRegistrations.value;

    if (this.type === SubscriptionDialogType.ADD) {
      this.addSubscription();
    } else {
      this.editSubscription();
    }
  }

  public getErrorMessageForExpireDate(): string {
    if (this.form.controls.expireDate.hasError('required')) {
      return this.translateService.instant('SUBSCRIPTIONS.DIALOG.ERROR_EXPIRE_DATE.MISSING_OR_INVALID_FORMAT');
    }
  }

  public getErrorMessageForMaxNumberOfMembers(): string {
    if (this.form.controls.maxNumberOfRegistrations.hasError('required')) {
      return this.translateService.instant('SUBSCRIPTIONS.DIALOG.ERROR_MAX_NUMBER_OF_REGISTRATIONS.REQUIRED');
    } else if (this.form.controls.maxNumberOfRegistrations.hasError('pattern')) {
      return this.translateService.instant('SUBSCRIPTIONS.DIALOG.ERROR_MAX_NUMBER_OF_REGISTRATIONS.NOT_A_POSITIVE_INTEGER');
    } else if (this.form.controls.maxNumberOfRegistrations.hasError('min')) {
      return this.translateService.instant('SUBSCRIPTIONS.DIALOG.ERROR_MAX_NUMBER_OF_REGISTRATIONS.MUST_BE_AT_LEAST_THE_CURRENT_NUMBER_OF_REGISTRATIONS');
    }
  }

  private addSubscription() {
    this.organisationsHttpService.addSubscription(this.organisation.id, this.subscription)
      .pipe(this.spinnerService.register())
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  private editSubscription() {
    this.organisationsHttpService.updateSubscription(this.organisation.id, this.subscription)
      .pipe(this.spinnerService.register())
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  public onApplicationChange(id: string) {
    this.subscription.application = this.availableApplications.find(app => app.id === id);
  }

  public onSubscriptionTypeChange() {
    if (this.subscription.type === SubscriptionType.CORPORATE) {
      this.form.controls.maxNumberOfRegistrations.disable();
    } else {
      this.form.controls.maxNumberOfRegistrations.enable();
    }
  }

  private getDefaultSubscription(): ApplicationSubscription {
    const subscription = new ApplicationSubscription();
    subscription.application = this.availableApplications[0];
    subscription.expireDate = new Date(new Date().getFullYear() + 1, 0, 1);
    subscription.expired = false;
    subscription.maxNumberOfRegistrations = 5;
    subscription.numberOfRegistrations = 0;
    subscription.type = SubscriptionType.DEMO;
    return subscription;
  }

  private getCopyOfSubscription(subscriptionToBeCopied: ApplicationSubscription): ApplicationSubscription {
    const subscription = new ApplicationSubscription();
    subscription.application = subscriptionToBeCopied.application;
    subscription.expireDate = subscriptionToBeCopied.expireDate;
    subscription.expired = subscriptionToBeCopied.expired;
    subscription.maxNumberOfRegistrations = subscriptionToBeCopied.maxNumberOfRegistrations;
    subscription.numberOfRegistrations = subscriptionToBeCopied.numberOfRegistrations;
    subscription.type = subscriptionToBeCopied.type;
    return subscription;
  }

  public close() {
    this.dialogRef.close(false);
  }
}
