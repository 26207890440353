import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationSubscription } from 'src/app/models/application-subscription';
import { OrganisationListDto } from 'src/app/models/organisation-list-dto';
import { Organisation } from '../../models/organisation';
import { BaseHttpService } from './base-http.service';

@Injectable({ providedIn: 'root' })
export class OrganisationsHttpService extends BaseHttpService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getOrganisationsFilteredSortedPaginated(parameters: string): Observable<OrganisationListDto> {
    if (parameters) {
      return this.get<OrganisationListDto>(`organisations?${parameters}`).pipe(this.mapDates());
    } else {
      return this.get<OrganisationListDto>(`organisations?`).pipe(this.mapDates());
    }
  }

  public getOrganisation(organisationId: string): Observable<Organisation> {
    return this.get<Organisation>(`organisations/${organisationId}`).pipe(this.mapDates());
  }

  public addOrganisation(organisation: Organisation): Observable<Organisation> {
    return this.postT<Organisation>('organisations', organisation).pipe(this.mapDates());
  }

  public updateOrganisation(organisation: Organisation): Observable<Organisation> {
    return this.putT<Organisation>(`organisations/${organisation.id}`, organisation).pipe(this.mapDates());
  }

  public deleteOrganisation(organisationId: string) {
    return this.delete(`organisations/${organisationId}`);
  }

  public addSubscription(organisationId: string, subscription: ApplicationSubscription): Observable<Organisation> {
    return this.postT<Organisation>(`organisations/${organisationId}/subscriptions`, subscription).pipe(this.mapDates());
  }

  public updateSubscription(organisationId: string, subscription: ApplicationSubscription): Observable<Organisation> {
    return this.putT<Organisation>(`organisations/${organisationId}/subscriptions`, subscription).pipe(this.mapDates());
  }

  public deleteSubscription(organisationId: string, applicationId: string) {
    return this.delete(`organisations/${organisationId}/subscriptions/${applicationId}`);
  }

  public deleteOrganisationImage(organisationId: string) {
    return this.delete(`organisations/${organisationId}/media`);
  }

  public getOrganisationName(organisationId: string) {
    return this.get<Pick<Organisation, 'id' | 'name'>>(`organisations/${organisationId}/name`);
  }

}
