<mat-card>
  <mat-card-header>
    <mat-card-title>{{ 'ORGANISATIONS.MANAGEMENT.USERSTAB.TITLE' | translate }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <div class="container" [class.less-spacing]="lessSpacing">

      <app-filter class="filterbox" [filterItems]="filterItems" (filterChange)="onFilterChange()"
        [sortSetup]="sortSetup" [syncedMatSort]="matSort" (sortChange)="onSortChange()">
      </app-filter>

      <table mat-table [dataSource]="dataSource" matSort #matSort="matSort" [matSortDisableClear]="true">

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'USERS.LABEL_NAME' | translate }}</th>
          <td mat-cell *matCellDef="let user">
            <div class="avatar-and-name">
              <div class="avatar" style.backgroundImage="url({{ getUserMediaImageUrl(user) }})"></div>
              <div>{{ user.name || '&mdash;' }}</div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'USERS.LABEL_EMAIL' | translate }}</th>
          <td mat-cell *matCellDef="let user">{{ user.email }}</td>
        </ng-container>

        <ng-container matColumnDef="lastLogin">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'USERS.LABEL_LAST_LOGIN' | translate }}</th>
          <td mat-cell *matCellDef="let user">{{ user.lastLogin | date:'medium' }}</td>
        </ng-container>

        <ng-container matColumnDef="registrations">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'USERS.LABEL_REGISTRATIONS' | translate }}</th>
          <td mat-cell *matCellDef="let user">
            <div *ngFor="let registration of user.registrations" class="application-icon"
              style.backgroundImage="url({{ registration.application.avatar }})"
              [matTooltip]="registration.application.name">
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="admin">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'USERS.LABEL_ADMIN' | translate }}</th>
          <td mat-cell *matCellDef="let user">
            <mat-checkbox [checked]="isOrganisationManager(user)" [disabled]="true"></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let user">
            <button mat-icon-button matTooltip="{{ 'USERS.LABEL_RECENT_LOGIN' | translate }}"
              (click)="onShowRecentLoginsClick(user)">
              <mat-icon>list_alt</mat-icon>
            </button>
            <a mat-icon-button matTooltip="{{ 'TABLE.LABEL_ACTION_CONFIG' | translate }}"
              (click)="onEditUserClick(user)">
              <mat-icon>build</mat-icon>
            </a>
            <button mat-icon-button matTooltip="{{ 'TABLE.LABEL_ACTION_DELETE' | translate }}"
              (click)="onRemoveUserClick(user)" [disabled]="!canRemoveUser(user)">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[25, 100, 250, 500]" [showFirstLastButtons]="true">
      </mat-paginator>

    </div>

  </mat-card-content>
</mat-card>
