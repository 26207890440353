import { User } from '../models/user';

export class UserUtils {

  private static readonly DEFAULT_USER_IMAGE = '/assets/default-user-img.jpg';

  public static getUserMediaImageUrl(user: User) {
    return (user.avatar) ? user.avatar : UserUtils.DEFAULT_USER_IMAGE;
  }

}
