import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { Application } from 'src/app/models/application';
import { FilterItem } from 'src/app/models/filter-item';
import { SortDirectionType } from 'src/app/models/sort-item';
import { SubscriptionType } from 'src/app/models/subscription-type';
import { SortSetup } from './sort-setup';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnChanges, OnInit, OnDestroy {

  @Input() public filterText: boolean = true;
  @Input() public filterExpireDate: boolean = false;
  @Input() public filterApplication: boolean = false;
  @Input() public filterSubscriptionType: boolean = false;

  @Input() public filterItems: FilterItem[] = [];
  @Input() public applications: Application[];

  @Input() public sortSetup: SortSetup;
  @Input() public syncedMatSort: MatSort;
  @Input() public filterIsDisabled: boolean;

  @Output() public filterChange = new EventEmitter();
  @Output() public sortChange = new EventEmitter();

  public subscriptionTypes = Object.keys(SubscriptionType);
  public maxColumns: number = 0;

  public searchText: string;
  public selectedApplication: Application;
  public selectedSubscriptionType: SubscriptionType;
  public selectedExpireDate: Date;

  private syncedMatSortSubscription: Subscription;
  private sortingProgrammatically: boolean = false;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .subscribe(_state => this.updateMaxColumns());
  }

  private updateMaxColumns(): void {
    if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.maxColumns = 1;
    } else if (this.breakpointObserver.isMatched([Breakpoints.Small, Breakpoints.Medium])) {
      this.maxColumns = 2;
    } else {
      this.maxColumns = 0;
    }
  }

  public ngOnInit() {
    if (this.syncedMatSort) {
      const selectedSortItem = this.sortSetup.getSelected();
      if (selectedSortItem) {
        this.syncedMatSort.active = selectedSortItem.columnId;
        this.syncedMatSort.direction = selectedSortItem.direction === SortDirectionType.ASCENDING ? 'asc' : 'desc';
      }
      this.syncedMatSortSubscription = this.syncedMatSort.sortChange.subscribe((sort: Sort) => this.onMatSortHeaderClick(sort));
    }
  }

  public ngOnChanges() {
    this.selectedExpireDate = this.getValue('expireDate') ? new Date(this.getValue('expireDate')) : null;
    const applicationId = this.getValue('applicationId');
    this.selectedApplication = this.applications?.find(app => app.id === applicationId) ?? null;
    this.selectedSubscriptionType = this.getValue('subscriptionType') as SubscriptionType;
    this.searchText = this.getValue('filterText');
  }

  private getValue(filterKey: string) {
    const filterItem = this.filterItems.find(item => item.key === filterKey);
    return filterItem ? filterItem.value as string : null;
  }

  public ngOnDestroy() {
    if (this.syncedMatSortSubscription) {
      this.syncedMatSortSubscription.unsubscribe();
    }
  }

  public onFilterChange(key: string, value: string | string[]) {
    if (this.filterItems.find(i => i.key === key)) {
      if (!value) {
        this.filterItems.splice(this.filterItems.findIndex(i => i.key === key), 1);
      } else {
        this.filterItems.find(i => i.key === key).value = value;
      }
    } else if (value) {
      this.filterItems.push({ key, value });
    }
    this.filterChange.emit();
  }

  public onFilterReset() {
    this.filterItems.splice(0, this.filterItems.length);
    if (this.searchText) {
      this.searchText = '';
    }
    if (this.selectedExpireDate) {
      this.selectedExpireDate = null;
    }
    if (this.selectedApplication) {
      this.selectedApplication = null;
    }
    if (this.selectedSubscriptionType) {
      this.selectedSubscriptionType = null;
    }
    this.filterChange.emit();
  }

  private onMatSortHeaderClick(sort: Sort) {
    if (!this.sortingProgrammatically) {
      let activeSortItem = null;
      if (sort.active) {
        activeSortItem = this.sortSetup.items.find(sortItem => sortItem.columnId === sort.active && (
          (sortItem.direction === SortDirectionType.ASCENDING && sort.direction === 'asc') ||
          (sortItem.direction === SortDirectionType.DESCENDING && sort.direction === 'desc')
        ));
      }
      this.sortSetup.items.forEach(sortItem => sortItem.selected = (sortItem === activeSortItem));
      this.sortChange.emit();
    }
  }

}
