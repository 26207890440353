export enum SortDirectionType {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING'
}

export class SortItem {
  columnId: string;
  urlParameter: string;
  title: string;
  selected: boolean;
  direction: SortDirectionType;

  public static create(columnId: string, urlParameter: string, direction: SortDirectionType): SortItem {
    return { columnId, urlParameter, title: '', direction, selected: false };
  }

  public static createAscDesc(columnId: string, urlParameter: string): [SortItem, SortItem] {
    return [{ columnId, urlParameter, title: '', direction: SortDirectionType.ASCENDING, selected: false },
    { columnId, urlParameter, title: '', direction: SortDirectionType.DESCENDING, selected: false }];
  }
}
