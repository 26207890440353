import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from './base-http.service';
import { Observable } from 'rxjs';
import { Application } from 'src/app/models/application';
import { EditExpireDateBatchJob } from 'src/app/models/edit-expire-date-batch-job';
import { EditExpireDateBatchResult } from 'src/app/models/edit-expire-date-batch-result';

@Injectable({ providedIn: 'root' })
export class ApplicationHttpService extends BaseHttpService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getApplication(applicationId: string): Observable<Application> {
    return this.get(`applications/${applicationId}`);
  }

  public getApplications(): Observable<Application[]> {
    return this.get(`applications`);
  }

  public postEditExpireDateBatchJob(applicationId: string, editExpireDateBatchJob: EditExpireDateBatchJob): Observable<any> {
    return this.postT<EditExpireDateBatchResult>(`applications/${applicationId}/edit-expire-date-job`, editExpireDateBatchJob);
  }

}
