import { SelectionModel } from '@angular/cdk/collections';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Application } from 'src/app/models/application';
import { FilterItem } from 'src/app/models/filter-item';
import { Organisation } from 'src/app/models/organisation';
import { SortDirectionType, SortItem } from 'src/app/models/sort-item';
import { FilterService } from 'src/app/services/filter.service';
import { ApplicationHttpService } from 'src/app/services/http/application-http-service';
import { OrganisationsHttpService } from 'src/app/services/http/organisations-http.service';
import { MessageService } from 'src/app/services/message.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { GeneralConstants } from 'src/app/utils/general-constants';
import { LocalStorageConstants } from 'src/app/utils/local-storage-constants';
import { OrganisationAddDialogComponent } from './organisation-add-dialog/organisation-add-dialog.component';
import { SortSetup } from '../shared/filter/sort-setup';

@Component({
  selector: 'app-organisations',
  templateUrl: './organisations.component.html',
  styleUrls: ['./organisations.component.scss']
})
export class OrganisationsComponent implements OnInit {

  private static readonly DEFAULT_COLUMNS = ['select', 'name', 'applications', 'domainFilter', 'actions'];
  private static readonly COMPACT_COLUMNS = OrganisationsComponent.DEFAULT_COLUMNS;

  public displayedColumns: string[] = OrganisationsComponent.DEFAULT_COLUMNS;
  public dataSource: MatTableDataSource<Organisation> = new MatTableDataSource();

  public selection = new SelectionModel<Organisation>(true, []);
  public multiSelectActive = false;
  public showSelectedActive = false;

  public sortSetup = SortSetup
    .create({ name: 'NAME', applications: 'NUMBER_OF_LICENSES', domainFilter: 'DOMAIN_FILTER' })
    .select('name', SortDirectionType.ASCENDING)
    .selectFromStorage(LocalStorageConstants.SORT_MODE_ORGANISATIONS);

  public filterItems: FilterItem[] = [];
  public applications: Application[] = [];

  public selectedApplicationId: string;

  public pageIndex: number = 0;
  public pageSize: number = 25;
  public totalCount: number;

  public lessSpacing: boolean = false;

  constructor(
    private dialog: MatDialog,
    private organisationsHttpService: OrganisationsHttpService,
    private applicationsHttpService: ApplicationHttpService,
    private filterService: FilterService,
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    breakpointObserver: BreakpointObserver) {

    breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium]).subscribe(state =>
      this.lessSpacing = state.matches);
    breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(state =>
      this.displayedColumns = state.matches ? OrganisationsComponent.COMPACT_COLUMNS : OrganisationsComponent.DEFAULT_COLUMNS);
  }

  public ngOnInit() {
    this.filterItems = this.filterService.getFromStorage('organisations');
    this.fetchOrganisations();
    this.fetchApplications();
  }

  public paginate(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.fetchOrganisations();
  }

  public onFilterChange() {
    this.selectedApplicationId = <string>this.filterItems.find(i => i.key === 'applicationId')?.value || null;
    this.pageIndex = 0;
    this.fetchOrganisations();
    this.filterService.setToStorage('organisations', this.filterItems);
  }

  public onSortChange() {
    this.sortSetup.saveToStorage(LocalStorageConstants.SORT_MODE_ORGANISATIONS);
    this.showSelectedActive ? this.refreshOrganisationsSelected() : this.fetchOrganisations();
  }

  public onAddOrganisationClick() {
    const organisationToAdd = new Organisation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = organisationToAdd;
    dialogConfig.width = '500px';
    const dialogRef = this.dialog.open(OrganisationAddDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(ok => ok && this.fetchOrganisations());
  }

  public onDeleteOrganisationClick(organisation: Organisation) {
    this.messageService.showOkCancelDialog({
      titleLangKey: 'ORGANISATIONS.LABEL_ACTION_DELETE',
      messageLangKey: 'ORGANISATIONS.DELETE_CONFIRM',
      langParams: { name: organisation.name }
    }, null, () => {
      this.organisationsHttpService.getOrganisation(organisation.id)
        .pipe(this.spinnerService.register())
        .subscribe((fetchedOrganisation) => {
          if (fetchedOrganisation.users.length > 0) {
            this.messageService.showErrorSnackBar('ORGANISATIONS.MESSAGE_ORGANISATION_HAS_USERS', { name: fetchedOrganisation.name });
          } else {
            this.organisationsHttpService.deleteOrganisation(organisation.id)
              .pipe(this.spinnerService.register())
              .subscribe(_ => this.fetchOrganisations());
          }
        });
    });
  }

  public getOrganisationImageUrl(organisationId: string) {
    return GeneralConstants.API_PREFIX + '/organisations/' + organisationId + '/media';
  }

  public onRefreshSelectedNeeded() {
    this.refreshOrganisationsSelected();
  }

  public onShowSelectedActiveChange(active: boolean) {
    this.showSelectedActive = active;
    if (this.showSelectedActive) {
      this.pageIndex = 0;
      this.refreshOrganisationsSelected();
      this.totalCount = this.selection.selected.length;
    } else {
      this.onFilterChange();
    }
  }

  public onMultiSelectActiveChange(active: boolean) {
    this.multiSelectActive = active;
    if (!active) {
      this.onShowSelectedActiveChange(false);
      this.selection.clear();
    }
  }

  public onSelectFullPageClick() {
    this.isFullPageSelected() ?
      this.dataSource.data.forEach(organisation => this.selection.deselect(organisation)) :
      this.dataSource.data.forEach(organisation => this.selection.select(organisation));
  }

  public isFullPageSelected() {
    const selectableRowsOnPage = this.dataSource.data;
    const numSelected = this.selection.selected.filter(f => selectableRowsOnPage.find(o => o.id.localeCompare(f.id)) != null).length;
    return selectableRowsOnPage.length === numSelected;
  }

  private refreshOrganisationsSelected() {
    const sortItem = this.sortSetup.getSelected();
    this.dataSource.data = this.selection.selected
      .sort((a, b) => this.sortOrganisation(a, b, sortItem));
    this.totalCount = this.selection.selected.length;
  }

  private sortOrganisation(a: Organisation, b: Organisation, sortItem: SortItem) {
    let result = 0;
    if (sortItem.columnId === 'name') {
      result = a.name.localeCompare(b.name);
    }
    return sortItem.direction === SortDirectionType.ASCENDING ? result : -result;
  }

  private fetchApplications() {
    this.applicationsHttpService.getApplications()
      .pipe(this.spinnerService.register())
      .subscribe(
        applications => {
          this.applications = applications;
        }
      );
  }

  private fetchOrganisations() {
    const parameters = this.getQueryParams();
    this.organisationsHttpService.getOrganisationsFilteredSortedPaginated(parameters)
      .pipe(this.spinnerService.register())
      .subscribe(organisationsList => {
        if (this.getQueryParams() === parameters) {
          this.dataSource.data = organisationsList.organisations;
          this.totalCount = organisationsList.numberOfOrganisations;
        }
        this.dataSource.data.forEach(organisation => {
          const found = this.selection.selected.find(o => o.id === organisation.id);
          if (found !== undefined) {
            this.selection.deselect(found);
            this.selection.select(organisation);
          }
        });
      });
  }

  private getQueryParams() {
    return this.filterService.constructParametersPaged(this.filterItems, this.sortSetup.items, this.pageSize, this.pageIndex);
  }

}
