import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { InputDialogComponent, InputDialogData } from '../components/shared/messages/input-dialog/input-dialog.component';
import { MessageDialogButtonType, MessageDialogComponent, MessageDialogData } from '../components/shared/messages/message-dialog/message-dialog.component';

export interface MessageDialogTexts {
  messageLangKey?: string;
  titleLangKey?: string;
  langParams?: any;
  templateRef?: TemplateRef<any>;
}

export interface InputDialogTexts {
  messageLangKey?: string;
  titleLangKey?: string;
  placeholderLangKey?: string;
  hintLangKey?: string;
  langParams?: any;
}

@Injectable({ providedIn: 'root' })
export class MessageService {

  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private dialog: MatDialog) {
  }

  public assert(condition: boolean, errorMessageOnFalse: string) {
    if (!condition) {
      this.snackBar.open(this.translateService.instant('MESSAGE.ERROR') + ': ' + errorMessageOnFalse,
        this.translateService.instant('GLOBAL.CLOSE'), { duration: 0 });
    }
  }

  public assertConsoleWarning(condition: boolean, errorMessageOnFalse: string) {
    if (!condition) {
      console.warn(errorMessageOnFalse);
    }
  }

  public showErrorSnackBar(messageLangKey: string, langParams?: any) {
    this.snackBar.open(this.translateService.instant(messageLangKey, langParams),
      this.translateService.instant('GLOBAL.CLOSE'), { duration: 0 });
  }

  public showInfoSnackBar(messageLangKey: string, langParams?: any) {
    this.snackBar.open(this.translateService.instant(messageLangKey, langParams), null, { duration: 3000 });
  }

  public showOkCancelDialog(
    text: MessageDialogTexts | string,
    width?: string,
    okClicked?: () => void, cancelClicked?: () => void,
    primaryButton?: MessageDialogButtonType) {
    if (typeof text === 'string') {
      text = { messageLangKey: text };
    }
    this.showMessageDialog(text, ['OK', 'CANCEL'], primaryButton, width, (button) => {
      if (button === 'OK' && okClicked) {
        okClicked();
      }
      if (button === 'CANCEL' && cancelClicked) {
        cancelClicked();
      }
    });
  }

  public showYesNoDialog(
    text: MessageDialogTexts | string,
    width?: string,
    yesClicked?: () => void, noClicked?: () => void,
    primaryButton?: MessageDialogButtonType) {
    if (typeof text === 'string') {
      text = { messageLangKey: text };
    }
    this.showMessageDialog(text, ['YES', 'NO'], primaryButton, width, (button) => {
      if (button === 'YES' && yesClicked) {
        yesClicked();
      }
      if (button === 'NO' && noClicked) {
        noClicked();
      }
    });
  }


  public showOkDialog(
    text: MessageDialogTexts | string,
    width?: string,
    okClicked?: () => void) {
    if (typeof text === 'string') {
      text = { messageLangKey: text };
    }
    this.showMessageDialog(text, ['OK'], 'OK', width, (button) => {
      if (okClicked) {
        okClicked();
      }
    });
  }

  public showMessageDialog(
    text: MessageDialogTexts,
    buttons?: MessageDialogButtonType[],
    primaryButton?: MessageDialogButtonType,
    width?: string,
    buttonClicked?: (button: MessageDialogButtonType) => void) {

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: width || 'auto',
      data: <MessageDialogData>{
        messageLangKey: text.messageLangKey,
        titleLangKey: text.titleLangKey,
        langParams: text.langParams || {},
        templateRef: text.templateRef,
        buttons: buttons || ['OK'],
        primaryButton: primaryButton || (buttons && buttons[0]) || 'OK'
      }
    });
    dialogRef.afterClosed().subscribe(button => {
      if (buttonClicked) {
        buttonClicked(button);
      }
    });
  }

  public showInputDialog(
    value: string,
    text: InputDialogTexts,
    width?: string,
    okClicked?: (value: string) => void, cancelClicked?: () => void) {

    const dialogRef = this.dialog.open(InputDialogComponent, {
      width: width || 'auto',
      data: <InputDialogData>{
        value,
        messageLangKey: text.messageLangKey,
        titleLangKey: text.titleLangKey,
        placeholderLangKey: text.placeholderLangKey,
        hintLangKey: text.hintLangKey,
        langParams: text.langParams || {}
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (okClicked) {
          okClicked(result);
        }
      } else {
        if (cancelClicked) {
          cancelClicked();
        }
      }
    });
  }

}
