import { Component, Inject } from '@angular/core';
import { AsyncValidatorFn, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { AccountsRole } from 'src/app/models/accounts-role';
import { Organisation } from 'src/app/models/organisation';
import { User } from 'src/app/models/user';
import { UserDto } from 'src/app/models/user-dto';
import { UsersHttpService } from 'src/app/services/http/users-http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UserDialogData } from './user-dialog-data';
import { UserDialogType } from './user-dialog-type';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent {

  public type: UserDialogType;

  public user: UserDto;
  public organisation: Organisation;

  public form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<UserDialogComponent>,
    private usersHttpService: UsersHttpService,
    private spinnerService: SpinnerService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: UserDialogData
  ) {
    this.type = data.type;
    this.organisation = data.organisation;
    this.user = this.type === UserDialogType.EDIT
      ? this.makeUserDto(data.user, this.organisation.id)
      : this.makeEmptyUserDto(this.organisation.id);

    this.form = new FormGroup({
      name: new FormControl(this.user.name),
      email: new FormControl(this.user.email, this.type === UserDialogType.EDIT ? {} : {
        validators: this.getEmailValidators(),
        asyncValidators: this.getEmailAsyncValidator()
      }),
      organisationManager: new FormControl({
        value: this.user.organisationManager,
        disabled: this.isOrganisationManagerControlDisabled()
      })
    });
  }

  public isFormChanged() {
    return this.form.controls.email.value !== this.user.email
      || this.form.controls.name.value !== this.user.name
      || this.form.controls.organisationManager.value !== this.user.organisationManager;
  }

  public close() {
    this.dialogRef.close(false);
  }

  public save() {
    this.user.name = this.form.controls.name.value;
    this.user.email = this.form.controls.email.value;
    this.user.organisationManager = this.form.controls.organisationManager.value;

    (this.type === UserDialogType.ADD ? this.usersHttpService.addUser(this.user) : this.usersHttpService.editUser(this.user))
      .pipe(this.spinnerService.register())
      .subscribe(() => this.dialogRef.close(true));
  }

  public getEmailErrorMessage() {
    if (this.form.controls.email.hasError('invalidDomain')) {
      return this.translateService.instant('USERS.DIALOG.ERROR_EMAIL.NOT_VALID_WITH_DOMAIN', { domain: this.organisation.domainFilter });
    } else if (this.form.controls.email.hasError('notUniqueEmail')) {
      return this.translateService.instant('USERS.DIALOG.ERROR_EMAIL.NOT_UNIQUE');
    } else {
      return this.translateService.instant('USERS.DIALOG.ERROR_EMAIL.NOT_VALID_WITHOUT_DOMAIN');
    }
  }

  private makeUserDto(user: User, organisationId: string) {
    const userDto = new UserDto();
    userDto.id = user.id;
    userDto.name = user.name;
    userDto.email = user.email;
    userDto.avatar = user.avatar;
    userDto.organisationManager = (user.accountsRole === AccountsRole.ORGANISATION_MANAGER);
    userDto.organisationId = organisationId;
    return userDto;
  }

  private makeEmptyUserDto(organisationId: string): UserDto {
    const userDto = new UserDto();
    userDto.name = '';
    userDto.email = '';
    userDto.avatar = '';
    userDto.organisationManager = (this.organisation.users.length === 0);
    userDto.organisationId = organisationId;
    return userDto;
  }

  private isOrganisationManagerControlDisabled(): boolean {
    const numberOfOrganisationManagers = this.organisation.users
      .filter(user => user.id !== this.user.id)
      .filter(user => user.accountsRole === AccountsRole.ORGANISATION_MANAGER).length;
    return (this.type !== UserDialogType.ADD && numberOfOrganisationManagers === 0 && this.user.organisationManager)
      || this.organisation.users.length === 0 || (this.data.user && this.data.user.accountsRole === AccountsRole.SUPPORT);
  }

  private getEmailValidators() {
    return [Validators.required, Validators.email, this.getInvalidDomainValidator()];
  }

  private getInvalidDomainValidator(): ValidatorFn {
    const domainFilter = this.organisation.domainFilter;
    return control => {
      if (control.value && domainFilter && domainFilter !== control.value.split('@', 2)[1]) {
        return { invalidDomain: true };
      }
      return null;
    };
  }

  private getEmailAsyncValidator(): AsyncValidatorFn {
    return control => {
      return this.usersHttpService.isValidEmail(control.value, this.organisation.id).pipe(map(isValid => isValid ? null : { 'notUniqueEmail': true }));
    };
  }

}