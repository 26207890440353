<mat-accordion class="headers-align" [multi]="true">
  <mat-expansion-panel
    *ngFor="let subscription of subscriptions; trackBy: trackBySubscription">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="application-icon" style.backgroundImage="url({{ subscription.application.avatar }})"></div>
        <div>{{subscription.application.name}}</div>
      </mat-panel-title>
      <mat-panel-description>
        <div class="subscription-progress" *ngIf="subscription.type !== 'CORPORATE'; else corporateIcon">
          <mat-progress-bar mode="determinate" [value]="getPercentageOfRegistrationsUsed(subscription)"
            [color]="getProgressColor(subscription)">
          </mat-progress-bar>
          <div class="subscription-count">{{ subscription.numberOfRegistrations }} /
            {{subscription.maxNumberOfRegistrations}}</div>
        </div>
        <ng-template #corporateIcon>
          <div class="corporate-icon-container">
            <div class="mat-icon-container-div">
              <mat-icon
                matTooltip="{{ 'SUBSCRIPTIONS.CORPORATE_LICENSE_EXPLANATION_TEXT'  | translate }}">
                all_inclusive</mat-icon>
            </div>
          </div>
        </ng-template>
        <div class="subscription-expire">{{subscription.expireDate | date}}</div>
        <div class="subscription-type">{{'SUBSCRIPTIONS.TYPES.' + subscription.type | translate}}</div>
        <div *ngIf="currentUserHasSupportRole">
          <button mat-icon-button
            matTooltip="{{ 'ORGANISATIONS.MANAGEMENT.SUBSCRIPTIONSTAB.LABEL_CONFIG' | translate }}"
            (click)="onEditSubscriptionClicked(subscription); $event.stopPropagation();">
            <mat-icon>build</mat-icon>
          </button>
          <button mat-icon-button style="margin-left: 8px"
            matTooltip="{{ 'ORGANISATIONS.MANAGEMENT.SUBSCRIPTIONSTAB.LABEL_DELETE' | translate }}"
            (click)="onRemoveSubscriptionClicked(subscription); $event.stopPropagation();"
            [disabled]="subscription.numberOfRegistrations > 0">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-registrations *ngIf="subscription.type !== 'CORPORATE'; else corporateSubscriptionMessageTemplate" [users]="organisation?.users"
      [subscription]="subscription" (refresh)="emitRefresh()">
    </app-registrations>
    <ng-template #corporateSubscriptionMessageTemplate>
      <div class="corporate-license-explanation">
        <div>{{ 'SUBSCRIPTIONS.CORPORATE_LICENSE_EXPLANATION_TEXT' | translate }}</div>
      </div>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>