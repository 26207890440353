export class EditExpireDateBatchResult {
  organisationId: string;
  organisationName: string;
  result: EditExpireDateBatchResultType;
}

export enum EditExpireDateBatchResultType {
  SUCCESSFUL = 'SUCCESSFUL',
  ORGANISATION_DOES_NOT_EXIST = 'ORGANISATION_DOES_NOT_EXIST',
  NO_SUBSCRIPTION_FOR_THIS_APPLICATION = 'NO_SUBSCRIPTION_FOR_THIS_APPLICATION',
  NO_UPDATE = 'NO_UPDATE',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}