<h2 mat-dialog-title>{{ 'MFA.ENABLE_DIALOG.TITLE' | translate }}</h2>

<mat-dialog-content class="mat-typography">

  <p>{{ 'MFA.APPS' | translate }}</p>
  <p>{{ 'MFA.ENABLE_DIALOG.SCAN_QR' | translate }}</p>

  <div class="qrcode">
    <canvas #qrCodeCanvas></canvas>
    <div class="secret">{{ secretBase32Encoded }}</div>
  </div>

  <p>{{ 'MFA.ENABLE_DIALOG.ENTER_VERIFICATION' | translate }}</p>

  <mat-form-field>
    <mat-label>{{ 'MFA.ENABLE_DIALOG.VERIFICATION_CODE' | translate }}</mat-label>
    <input matInput type="text" required [(ngModel)]="verificationCode">
  </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="close()">{{ 'GLOBAL.CANCEL' | translate }}</button>
  <button mat-button color="primary" (click)="enableMultiFactorAuthentication()"
    [disabled]="verificationCode.trim().length === 0">
    {{ 'GLOBAL.OK' | translate }}
  </button>
</mat-dialog-actions>

<ng-template #recoveryCodesMessageBox>
  <p>{{ 'MFA.ENABLE_DIALOG.SUCCESS_TEXT' | translate }}</p>
  <p>{{ 'MFA.ENABLE_DIALOG.RECOVERY_CODES' | translate }}</p>
  <div class="recovery-codes">
    <div *ngFor="let recoveryCode of recoveryCodes">{{ recoveryCode }}</div>
  </div>
</ng-template>
