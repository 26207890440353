import { trigger, style, animate, transition, animation } from '@angular/animations';
import { GeneralConstants } from 'src/app/utils/general-constants';

export class MainPageLayoutAnimations {

  public static readonly ANIMATIONS = [
    trigger('preventInitialChildAnimations', [
      transition(':enter', [])
    ]),
    trigger('appearDisappearHeader', [
      transition(':enter', animation([
        style({ marginTop: `-${GeneralConstants.HEADER_HEIGHT}px` }),
        animate('0.5s ease', style({ marginTop: 0 }))
      ])),
      transition(':leave', animation([
        style({ marginTop: 0 }),
        animate('0.5s ease', style({ marginTop: `-${GeneralConstants.HEADER_HEIGHT}px` }))
      ]))
    ]),
    trigger('appearDisappearToolbar', [
      transition(':enter', animation([
        style({ marginTop: `-${GeneralConstants.TOOLBAR_HEIGHT}px` }),
        animate('0.5s ease', style({ marginTop: 0 }))
      ])),
      transition(':leave', animation([
        style({ marginTop: 0 }),
        animate('0.5s ease', style({ marginTop: `-${GeneralConstants.TOOLBAR_HEIGHT}px` }))
      ]))
    ])
  ];
}
