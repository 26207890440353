<app-main-page-layout pageLabel="{{ 'GLOBAL.MY_ACCOUNT' | translate }}" [footer]="true" [footerFixed]="true">

  <div class="container">
    <div class="cards">

      <mat-card>
        <div class="userbox">
          <img [src]="avatarUrl">
          <div class="user-details">
            <div class="user-full-name">{{ user.name ?? '&mdash;' }}</div>
            <div class="user-email">{{ user.email }}</div>
            <div class="user-group">{{ organisationName ?? '&mdash;' }}</div>
            <div class="user-registrations">
              <div *ngFor="let registration of user.registrations" class="application-icon"
                style.backgroundImage="url({{ registration.application.avatar }})"
                [matTooltip]="registration.application.name">
              </div>
            </div>
          </div>
        </div>
      </mat-card>

      <mat-card class="mfa">
        <mat-card-header>
          <mat-card-title>{{ 'MFA.TITLE' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="mfa-apps">{{ 'MFA.APPS' | translate }}</div>
          <div class="mfa-state" *ngIf="!user.multiFactorAuthentication">
            <mat-icon class="material-icons-outlined">cancel</mat-icon>
            <div>{{ 'MFA.IS_DISABLED' | translate }}</div>
            <button mat-stroked-button (click)="enableMultiFactorAuthentication()">{{ 'GLOBAL.ENABLE' | translate
              }}</button>
          </div>
          <div class="mfa-state" *ngIf="user.multiFactorAuthentication">
            <mat-icon class="material-icons-outlined">check_circle</mat-icon>
            <div>{{ 'MFA.IS_ENABLED' | translate }}</div>
            <button mat-stroked-button (click)="disableMultiFactorAuthentication()">{{ 'GLOBAL.DISABLE' | translate
              }}</button>
          </div>
        </mat-card-content>
      </mat-card>

    </div>
  </div>

  <app-page-spinner></app-page-spinner>

</app-main-page-layout>
