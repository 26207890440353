import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Application } from 'src/app/models/application';
import { EditExpireDateBatchJob } from 'src/app/models/edit-expire-date-batch-job';
import { EditExpireDateBatchResult, EditExpireDateBatchResultType } from 'src/app/models/edit-expire-date-batch-result';
import { ApplicationHttpService } from 'src/app/services/http/application-http-service';

interface EditExpireDateBatchJobDialogData {
  application: Application;
  organisationIds: string[];
}

@Component({
  selector: 'app-edit-expire-date-batch-job',
  templateUrl: './edit-expire-date-batch-job.component.html',
  styleUrls: ['./edit-expire-date-batch-job.component.scss']
})
export class EditExpireDateBatchJobComponent {

  public readonly SUCCESS = EditExpireDateBatchResultType.SUCCESSFUL;

  public selectedExpireDate: Date = null;
  public busy = false;
  public results: EditExpireDateBatchResult[];

  public static open(dialog: MatDialog, application: Application, organisationIds: string[]): Observable<boolean> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { application, organisationIds } as EditExpireDateBatchJobDialogData;
    dialogConfig.width = '500px';
    return dialog.open(EditExpireDateBatchJobComponent, dialogConfig).afterClosed();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: EditExpireDateBatchJobDialogData,
    private dialogRef: MatDialogRef<EditExpireDateBatchJobComponent>,
    private applicationHttpService: ApplicationHttpService) {
  }

  public close() {
    this.dialogRef.close(!!this.results);
  }

  public apply() {
    this.busy = true;
    const job: EditExpireDateBatchJob = { expireDate: this.selectedExpireDate, organisationIds: this.dialogData.organisationIds };
    this.applicationHttpService.postEditExpireDateBatchJob(this.dialogData.application.id, job)
      .pipe(finalize(() => this.busy = false))
      .subscribe(result => this.results = result);
  }
}
