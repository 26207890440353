<h2 mat-dialog-title>{{ 'USERS.LABEL_RECENT_LOGIN' | translate }}</h2>

<mat-dialog-content class="mat-typography">

  <table mat-table [dataSource]="data.recentUserLogin">

    <ng-container matColumnDef="applicationName">
      <th mat-header-cell *matHeaderCellDef>{{ 'USERS.RECENT_LOGIN.APPLICATION_NAME' | translate }}</th>
      <td mat-cell *matCellDef="let login">{{ login.applicationName ?? ('USERS.RECENT_LOGIN.UNKNOWN_APPLICATION_NAME' |
        translate) }}</td>
    </ng-container>

    <ng-container matColumnDef="lastLogin">
      <th mat-header-cell *matHeaderCellDef>{{ 'USERS.RECENT_LOGIN.LOGIN_DATE' | translate }}</th>
      <td mat-cell *matCellDef="let login">{{ login.loginDate | date:'medium' }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="close()">{{ 'GLOBAL.CLOSE' | translate }}</button>
</mat-dialog-actions>
