import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationRegistration } from 'src/app/models/application-registration';
import { ApplicationSubscription } from 'src/app/models/application-subscription';
import { User } from 'src/app/models/user';
import { UsersHttpService } from 'src/app/services/http/users-http.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { SubscriptionDialogComponent } from '../../subscription-dialog/subscription-dialog.component';
import { RegistrationAddDialogData } from './registration-add-dialog-data';

@Component({
  selector: 'app-registration-add-dialog',
  templateUrl: './registration-add-dialog.component.html',
  styleUrls: ['./registration-add-dialog.component.scss']
})
export class RegistrationAddDialogComponent {

  public userId: string;

  public subscription: ApplicationSubscription;
  public availableUsers: User[];

  constructor(
    private dialogRef: MatDialogRef<SubscriptionDialogComponent>,
    private spinnerService: SpinnerService,
    private usersHttpService: UsersHttpService,
    @Inject(MAT_DIALOG_DATA) public data: RegistrationAddDialogData
  ) {
    this.subscription = data.subscription;
    this.availableUsers = data.availableUsers;

    this.userId = this.availableUsers[0].id;
  }

  public addUserClicked() {
    const registration = new ApplicationRegistration();
    registration.application = this.subscription.application;
    registration.roles = [];
    this.usersHttpService.addRegistrationForUser(this.userId, registration)
      .pipe(this.spinnerService.register())
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  public close() {
    this.dialogRef.close(false);
  }
}
