import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationRegistration } from 'src/app/models/application-registration';
import { EnableMultiFactorAuthenticationRequest } from 'src/app/models/enable-multi-factor-authentication-request';
import { EnableMultiFactorAuthenticationResponse } from 'src/app/models/enable-multi-factor-authentication-response';
import { RecentUserLogin } from 'src/app/models/recent-user-login';
import { User } from 'src/app/models/user';
import { UserDto } from '../../models/user-dto';
import { BaseHttpService } from './base-http.service';

@Injectable({
  providedIn: 'root'
})
export class UsersHttpService extends BaseHttpService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getRecentUserLogins(userId: string) {
    return this.get<RecentUserLogin[]>(`users/${userId}/recentlogins`).pipe(this.mapDates());
  }

  public addUser(userDto: UserDto): Observable<UserDto> {
    return this.postT<UserDto>('users', userDto);
  }

  public isValidEmail(email: string, organisationId: string): Observable<boolean> {
    const body = {
      email,
      organisationId
    };
    return this.postT<boolean>('users/isvalidemail', body);
  }

  public deleteUser(userId: string) {
    return this.delete(`users/${userId}`);
  }

  public editUser(user: UserDto): Observable<UserDto> {
    return this.putT<UserDto>(`users/${user.id}`, user);
  }

  public addRegistrationForUser(userId: string, registration: ApplicationRegistration): Observable<User> {
    return this.postT<User>(`users/${userId}/registrations`, registration);
  }

  public deleteRegistrationForUser(userId: string, applicationId: string) {
    return this.delete(`users/${userId}/registrations/${applicationId}`);
  }

  public enableMultiFactorAuthentication(userId: string, request: EnableMultiFactorAuthenticationRequest) {
    return this.postT<EnableMultiFactorAuthenticationResponse>(`users/${userId}/mfa`, request);
  }

  public disableMultiFactorAuthentication(userId: string, verificationCode: string) {
    return this.delete(`users/${userId}/mfa?verificationCode=${verificationCode}`);
  }

}
