<h2 mat-dialog-title>{{ 'ORGANISATIONS.BATCH_EDIT_EXPIRE_DATE.TITLE' | translate }}</h2>

<mat-dialog-content class="mat-typography" [class.fixedheight]="!results">

  <form #form="ngForm">

    <ng-container *ngIf="!results && !busy">
      <p>
        {{ 'ORGANISATIONS.BATCH_EDIT_EXPIRE_DATE.HINT' | translate:{applicationName: dialogData.application.name,
        numOrganisations: dialogData.organisationIds.length } }}
      </p>

      <mat-form-field>
        <mat-label>{{'ORGANISATIONS.BATCH_EDIT_EXPIRE_DATE.EXPIREDATE' | translate}}</mat-label>
        <input matInput required [matDatepicker]="expireDatePicker" [(ngModel)]="selectedExpireDate" name="expiredate">
        <mat-datepicker-toggle matSuffix [for]="expireDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #expireDatePicker></mat-datepicker>
      </mat-form-field>
    </ng-container>

    <div class="busy" *ngIf="busy">
      <mat-spinner [diameter]="48"></mat-spinner>
    </div>

    <div *ngIf="results">
      <table mat-table [dataSource]="results">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'ORGANISATIONS.BATCH_EDIT_EXPIRE_DATE.ORGANISATION' | translate }}
          </th>
          <td mat-cell *matCellDef="let result">{{ result.organisationName }}</td>
        </ng-container>
        <ng-container matColumnDef="result">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'ORGANISATIONS.BATCH_EDIT_EXPIRE_DATE.RESULT_LABEL' | translate }}
          </th>
          <td mat-cell *matCellDef="let result">
            <mat-icon>{{ result.result === SUCCESS ? 'check_circle' : 'cancel' }}</mat-icon>
            {{ 'ORGANISATIONS.BATCH_EDIT_EXPIRE_DATE.RESULT.' + result.result | translate }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['name', 'result']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['name', 'result'];"></tr>
      </table>
    </div>

  </form>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="close()" [disabled]="busy">
    {{ (results ? 'GLOBAL.CLOSE' : 'GLOBAL.CANCEL') | translate }}
  </button>
  <button mat-button (click)="apply()" [disabled]="busy || !form.valid" *ngIf="!results" color="primary">
    {{ 'GLOBAL.EDIT' | translate }}
  </button>
</mat-dialog-actions>