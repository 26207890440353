import { Component, Inject, HostBinding, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { UserFriendlyException } from './user-friendly-exception';
import { UserFriendlyExceptionType } from './user-friendly-exception-type';
import { AuthorisationService } from 'src/app/services/authorisation.service';

@Component({
  selector: 'app-http-error-message',
  templateUrl: './http-error-message.component.html',
  styleUrls: ['./http-error-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HttpErrorMessageComponent {

  @HostBinding('class.mat-simple-snackbar') useDefaultStyle: boolean = true;

  public messageKey: string;
  public langParams: any;
  public showLoginLink: boolean;

  constructor(private router: Router, private snackBar: MatSnackBar,
    private authorisationService: AuthorisationService,
    @Inject(MAT_SNACK_BAR_DATA) public snackBarData: { request: HttpRequest<any>, errorResponse: HttpErrorResponse }) {
    if (Object.keys(UserFriendlyExceptionType).indexOf(snackBarData.errorResponse.error.type) !== - 1) {
      const userFriendlyException: UserFriendlyException = snackBarData.errorResponse.error;
      this.messageKey = 'SNACKBAR_HTTP_ERROR.USER_FRIENDLY_MESSAGE.' + userFriendlyException.type;
      this.langParams = userFriendlyException.parameters;
    } else {
      this.messageKey = this.getGeneralMessageKey(snackBarData.errorResponse.status);
    }
    this.showLoginLink = snackBarData.errorResponse.status === 401;
  }

  public onCloseClick() {
    this.snackBar.dismiss();
  }

  public onLoginClick() {
    this.snackBar.dismiss();
    const returnPath = this.router.routerState.snapshot.url;
    this.authorisationService.login(returnPath);
  }

  private getGeneralMessageKey(statusCode: number): string {
    switch (statusCode) {
      case 401:
        return 'SNACKBAR_HTTP_ERROR.STATUS_UNAUTHORIZED';
      case 403:
        return 'SNACKBAR_HTTP_ERROR.STATUS_FORBIDDEN';
      case 404:
        return 'SNACKBAR_HTTP_ERROR.STATUS_NOTFOUND';
      case 0:
        return 'SNACKBAR_HTTP_ERROR.STATUS_NONE';
      case 500:
      default:
        return 'SNACKBAR_HTTP_ERROR.STATUS_SERVER_ERROR';
    }
  }

}
