<div @preventInitialChildAnimations class="host">

  <app-header *ngIf="header" @appearDisappearHeader [pageLabel]="pageLabel" [statusIcon]="statusIcon">
  </app-header>

  <div class="additional-headers" @appearDisappearToolbar *ngIf="toolbar && toolbarVisible"
    (@appearDisappearToolbar.done)="onHeightChange()">
    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
  </div>

  <div class="page-content" #pageContent (scroll)="onPageContentScroll()">
    <div class="page-wrap" [class.has-footer]="footer && !footerFixed" (scroll)="onPageContentScroll()">
      <ng-content></ng-content>
    </div>
    <div class="footer-floater" [style.margin-bottom.px]="footerFloaterBottomMargin">
      <ng-content select="[footerFloater]"></ng-content>
    </div>
    <app-footer *ngIf="footer && !footerFixed"></app-footer>
  </div>

  <app-footer *ngIf="footer && footerFixed" class="footer-always"></app-footer>
</div>
