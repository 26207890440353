<h2 mat-dialog-title>{{ 'SUBSCRIPTIONS.' + type + '_DIALOG.TITLE' | translate }}</h2>
<mat-dialog-content class="mat-typography">

  <div class="form-container">

    <mat-form-field>
      <mat-label>{{'SUBSCRIPTIONS.DIALOG.APPLICATION' | translate}}</mat-label>
      <mat-select [(ngModel)]="applicationId" (selectionChange)="onApplicationChange($event.value)"
        [disabled]="type === 'EDIT'">
        <mat-option *ngFor="let application of availableApplications" [value]="application.id">
          {{ application.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{'SUBSCRIPTIONS.DIALOG.LICENSE_TYPE' | translate}}</mat-label>
      <mat-select [(ngModel)]="subscription.type" (selectionChange)="onSubscriptionTypeChange()">
        <mat-option *ngFor="let subscriptionType of SUBSCRIPTION_TYPES" [value]="subscriptionType">
          {{'SUBSCRIPTIONS.TYPES.' + subscriptionType | translate}}
        </mat-option>
        <mat-option [value]="'CORPORATE'" [disabled]="!organisation.domainFilter">{{'SUBSCRIPTIONS.TYPES.CORPORATE' |
          translate}} <span *ngIf="!organisation.domainFilter"> ({{'SUBSCRIPTIONS.DIALOG.DOMAIN_FILTER_REQUIRED_TEXT' |
            translate }})</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <form [formGroup]="form">

      <mat-form-field *ngIf="subscription.type !== 'CORPORATE'">
        <mat-label>{{ 'SUBSCRIPTIONS.DIALOG.MAX_NUMBER_OF_REGISTRATIONS' | translate}} </mat-label>
        <input formControlName="maxNumberOfRegistrations" matInput required type="number" pattern="\d+"
          (keydown.enter)="$any($event.target).blur()">
        <mat-error *ngIf="form.controls.maxNumberOfRegistrations.invalid">{{getErrorMessageForMaxNumberOfMembers()}}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{'SUBSCRIPTIONS.DIALOG.EXPIRE_DATE' | translate}}</mat-label>
        <input matInput required [matDatepicker]="expireDatePicker" formControlName="expireDate">
        <mat-error *ngIf="form.controls.expireDate.invalid">{{getErrorMessageForExpireDate()}}</mat-error>
        <mat-datepicker-toggle matSuffix [for]="expireDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #expireDatePicker></mat-datepicker>
      </mat-form-field>

    </form>

  </div>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="close()">{{ 'GLOBAL.CANCEL' | translate }}</button>
  <button mat-button color="primary" (click)="save()" [disabled]="form.invalid">
    {{(type === 'EDIT' ? 'GLOBAL.EDIT' : 'GLOBAL.ADD') | translate }}</button>
</mat-dialog-actions>
