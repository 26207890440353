import { OverlayModule } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { AccountComponent } from './components/account/account.component';
import { EnableMfaDialogComponent } from './components/account/enable-mfa-dialog/enable-mfa-dialog.component';
import { LoginCallbackComponent } from './components/login-callback/login-callback.component';
import { InformationComponent } from './components/organisation-management/information/information.component';
import { MediaComponent } from './components/organisation-management/media/media.component';
import { OrganisationManagementComponent } from './components/organisation-management/organisation-management.component';
import {
  RegistrationAddDialogComponent
} from './components/organisation-management/subscriptions/registrations/registration-add-dialog/registration-add-dialog.component';
import { RegistrationsComponent } from './components/organisation-management/subscriptions/registrations/registrations.component';
import { SubscriptionDialogComponent } from './components/organisation-management/subscriptions/subscription-dialog/subscription-dialog.component';
import { SubscriptionsComponent } from './components/organisation-management/subscriptions/subscriptions.component';
import { UserDialogComponent } from './components/organisation-management/users/user-dialog/user-dialog.component';
import { UserRecentLoginsDialogComponent } from './components/organisation-management/users/user-recent-logins-dialog/user-recent-logins-dialog.component';
import { UsersComponent } from './components/organisation-management/users/users.component';
import { EditExpireDateBatchJobComponent } from './components/organisations/edit-expire-date-batch-job/edit-expire-date-batch-job.component';
import { OrganisationAddDialogComponent } from './components/organisations/organisation-add-dialog/organisation-add-dialog.component';
import {
  OrganisationsMultiSelectToolbarComponent
} from './components/organisations/organisations-multi-select-toolbar/organisations-multi-select-toolbar.component';
import { OrganisationsComponent } from './components/organisations/organisations.component';
import { FilterComponent } from './components/shared/filter/filter.component';
import { ImageUploadComponent } from './components/shared/image-upload/image-upload.component';
import { AppDrawerComponent } from './components/shared/main-page-layout/app-drawer/app-drawer.component';
import { FooterComponent } from './components/shared/main-page-layout/footer/footer.component';
import { HeaderComponent } from './components/shared/main-page-layout/header/header.component';
import { MainPageLayoutComponent } from './components/shared/main-page-layout/main-page-layout.component';
import { HttpErrorMessageComponent } from './components/shared/messages/http-error-message/http-error-message.component';
import { InputDialogComponent } from './components/shared/messages/input-dialog/input-dialog.component';
import { MessageDialogComponent } from './components/shared/messages/message-dialog/message-dialog.component';
import { PageSpinnerComponent } from './components/shared/page-spinner/page-spinner.component';
import { RouterAnchorDisabledDirective } from './directives/router-anchor-disabled';
import { AppContext } from './helpers/app-context';
import { CustomMatPaginatorIntl } from './helpers/custom-mat-paginator-intl';
import { IterablePipe } from './pipes/iterable.pipe';
import { ErrorInterceptor } from './services/http/interceptors/error.interceptor';
import { DynamicLocaleId, LanguageUtils } from './utils/language-utils';

registerLocaleData(localeNl, 'nl');

@NgModule({

  declarations: [
    // Directives
    RouterAnchorDisabledDirective,

    // Pipes
    IterablePipe,

    // Components
    AppComponent,
    LoginCallbackComponent,// dummy component for redirect route

    FilterComponent,
    FooterComponent,
    HeaderComponent,
    MainPageLayoutComponent,
    MessageDialogComponent,
    InputDialogComponent,
    HttpErrorMessageComponent,
    ImageUploadComponent,
    PageSpinnerComponent,

    OrganisationsComponent,
    OrganisationAddDialogComponent,
    OrganisationManagementComponent,
    UsersComponent,
    UserDialogComponent,
    InformationComponent,
    SubscriptionsComponent,
    RegistrationsComponent,
    SubscriptionDialogComponent,
    RegistrationAddDialogComponent,
    MediaComponent,
    OrganisationsMultiSelectToolbarComponent,
    EditExpireDateBatchJobComponent,
    UserRecentLoginsDialogComponent,
    AccountComponent,
    EnableMfaDialogComponent,
    AppDrawerComponent
  ],
  imports: [
    // Angular
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,

    // Material
    MatTooltipModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatButtonToggleModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatListModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatDialogModule,
    MatBadgeModule,
    OverlayModule,

    // Other libs
    TranslateModule.forRoot(LanguageUtils.getTranslateModuleConfig())
  ],

  providers: [
    AppContext,
    { provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService] },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
}
