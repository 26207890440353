import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AppContext } from 'src/app/helpers/app-context';
import { AccountsRole } from 'src/app/models/accounts-role';
import { ApplicationSubscription } from 'src/app/models/application-subscription';
import { Organisation } from 'src/app/models/organisation';
import { OrganisationsHttpService } from 'src/app/services/http/organisations-http.service';
import { MessageService } from 'src/app/services/message.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { SubscriptionUtils } from 'src/app/utils/subscription-utils';
import { SubscriptionDialogData } from './subscription-dialog/subscription-dialog-data';
import { SubscriptionDialogType } from './subscription-dialog/subscription-dialog-type';
import { SubscriptionDialogComponent } from './subscription-dialog/subscription-dialog.component';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent {

  public subscriptions: ApplicationSubscription[];

  private _organisation: Organisation;

  get organisation() {
    return this._organisation;
  }

  @Input() set organisation(organisation: Organisation) {
    if (organisation) {
      this._organisation = organisation;
      this.subscriptions = this.organisation.subscriptions.sort((a, b) => a.application.name.localeCompare(b.application.name));
    }
  }
  @Output() refresh = new EventEmitter();

  public currentUserHasSupportRole = false;

  constructor(
    private organisationsHttpService: OrganisationsHttpService,
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private appContext: AppContext,
    private dialog: MatDialog
  ) {
    this.currentUserHasSupportRole = (this.appContext.user.accountsRole === AccountsRole.SUPPORT);
  }

  public onEditSubscriptionClicked(subscription: ApplicationSubscription) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogData: SubscriptionDialogData = {
      availableApplications: [subscription.application],
      subscription,
      organisation: this.organisation,
      type: SubscriptionDialogType.EDIT
    };
    dialogConfig.data = dialogData;
    dialogConfig.width = '600px';
    const dialogRef = this.dialog.open(SubscriptionDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(ok => ok && this.refresh.emit());
  }

  public onRemoveSubscriptionClicked(subscription: ApplicationSubscription) {
    this.messageService.showOkCancelDialog({
      titleLangKey: 'SUBSCRIPTIONS.LABEL_ACTION_DELETE',
      messageLangKey: 'SUBSCRIPTIONS.DELETE_CONFIRM',
      langParams: { license: subscription.application.name }
    }, null, () => {
      this.organisationsHttpService.deleteSubscription(this.organisation.id, subscription.application.id)
        .pipe(this.spinnerService.register())
        .subscribe(() => {
          this.refresh.emit();
        });
    });
  }

  public getPercentageOfRegistrationsUsed(subscription: ApplicationSubscription): number {
    return SubscriptionUtils.getPercentageOfRegistrationsUsed(subscription);
  }

  public getProgressColor(subscription: ApplicationSubscription): ThemePalette {
    return SubscriptionUtils.getProgressColor(subscription);
  }

  public emitRefresh() {
    this.refresh.emit();
  }

  public trackBySubscription(_index: number, subscription: ApplicationSubscription) {
    return subscription.application.id;
  }
}