import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user';
import { BaseHttpService } from './base-http.service';

@Injectable({ providedIn: 'root' })
export class AuthorisationHttpService extends BaseHttpService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  public getBootstrapInfo(): Observable<{ currentUser: User; }> {
    return this.get(`authorisation/bootstrapinfo`);
  }

}
