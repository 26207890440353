import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AppContext } from 'src/app/helpers/app-context';

@Component({
  selector: 'app-app-drawer',
  templateUrl: './app-drawer.component.html',
  styleUrls: ['./app-drawer.component.scss']
})

export class AppDrawerComponent {

  private static readonly ACCOUNTS_HOSTNAME: string = 'accounts.omnitransnext.dat.nl';
  private static readonly ACCOUNTS_APPDRAWER_API_URL: string = '/api/v1/appdrawer';

  private static drawerHtml: SafeHtml;

  constructor(private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private appContext: AppContext) {
  }

  get drawerHtml() {
    return AppDrawerComponent.drawerHtml;
  }

  public getAppDrawerContents() {
    if (!this.drawerHtml) {
      this.httpClient.get(this.getAppDrawerUrl(), { responseType: 'text' }).subscribe(response => {
        AppDrawerComponent.drawerHtml = this.sanitizer.bypassSecurityTrustHtml(response);
      });
    }
  }

  private getAppDrawerUrl() {
    const userUuid = this.appContext.user.id;
    const urlPath = `${AppDrawerComponent.ACCOUNTS_APPDRAWER_API_URL}/${userUuid}`;
    if (window.location.hostname === 'localhost') {
      return urlPath;
    } else if (window.location.hostname.startsWith('dev.') || window.location.hostname.startsWith('test.')) {
      return `https://dev.${AppDrawerComponent.ACCOUNTS_HOSTNAME}${urlPath}`;
    } else if (window.location.hostname.startsWith('staging.') || window.location.hostname.startsWith('accp.')) {
      return `https://staging.${AppDrawerComponent.ACCOUNTS_HOSTNAME}${urlPath}`;
    } else {
      return `https://${AppDrawerComponent.ACCOUNTS_HOSTNAME}${urlPath}`;
    }
  }

}
