import { ThemePalette } from '@angular/material/core';
import { ApplicationSubscription } from '../models/application-subscription';

export class SubscriptionUtils {

  public static getPercentageOfRegistrationsUsed(subscription: ApplicationSubscription): number {
    return 100 * subscription.numberOfRegistrations / subscription.maxNumberOfRegistrations;
  }

  public static getProgressColor(subscription: ApplicationSubscription): ThemePalette {
    const progress = this.getPercentageOfRegistrationsUsed(subscription);
    if (progress >= 100) {
      return 'accent';
    } else if (progress >= 80) {
      return 'warn';
    } else {
      return 'primary';
    }
  }

}
