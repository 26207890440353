import { Routes } from '@angular/router';
import { AccountComponent } from './components/account/account.component';
import { LoginCallbackComponent } from './components/login-callback/login-callback.component';
import { OrganisationManagementComponent } from './components/organisation-management/organisation-management.component';
import { OrganisationsComponent } from './components/organisations/organisations.component';
import { AuthThenRedirectGuard } from './guards/auth-then-redirect.guard';
import { AuthGuard } from './guards/auth.guard';

export const appRoutes: Routes = [
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'organisations',
    component: OrganisationsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'organisations/:id',
    component: OrganisationManagementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: LoginCallbackComponent, // never entered
    canActivate: [AuthThenRedirectGuard]
  },
  {
    path: '**',
    redirectTo: '/'
  }
];
