export class GeneralConstants {
  
  public static readonly API_PREFIX = '/api/v1';

  // These are also in variables.scss:
  public static readonly HEADER_HEIGHT = 64;
  public static readonly FOOTER_HEIGHT = 32;
  public static readonly TOOLBAR_HEIGHT = 48;

}
