import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserRecentLoginsDialogData } from './user-recent-logins-dialog-data';

@Component({
  selector: 'app-user-recent-logins-dialog',
  templateUrl: './user-recent-logins-dialog.component.html',
  styleUrls: ['./user-recent-logins-dialog.component.scss']
})
export class UserRecentLoginsDialogComponent {

  public displayedColumns = ['applicationName', 'lastLogin'];

  constructor(
    private dialogRef: MatDialogRef<UserRecentLoginsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserRecentLoginsDialogData
  ) {
  }

  public close() {
    this.dialogRef.close(false);
  }
}
