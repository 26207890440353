import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UploadHttpService } from 'src/app/services/http/upload-http.service';
import { GeneralConstants } from 'src/app/utils/general-constants';


enum UploadStatus {
  SENT = 0,
  UPLOADING = 1,
  PROCESSING = 2,
  SUCCES = 3,
  ERROR = 4
}

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {

  @ViewChild('file', { static: false }) file;
  @Input() uploadUrl: string;
  @Output() public fileUploaded = new EventEmitter<string>();
  @Output() public fileUploadError = new EventEmitter<void>();

  private form: FormGroup;
  public selectedFileName: string;
  private action: string;

  public uploadStatus: UploadStatus = UploadStatus.SENT;
  public dropzoneHover = false;
  public fileSelected = false;

  constructor(
    private fb: FormBuilder,
    private uploadHttpService: UploadHttpService
  ) { }

  public ngOnInit() {
    this.form = this.fb.group({
      action: [this.action, []]
    });
  }

  public onFileSelected(event) {
    this.fileSelected = true;
    this.form.value.file = event.target.files[0];
    this.selectedFileName = event.target.files[0].name;

    this.upload();
  }

  public openFileDialog() {
    this.file.nativeElement.click();
  }

  public dropHandler(event) {
    event.preventDefault();

    let file;
    if (event.dataTransfer.items) {
      file = event.dataTransfer.items[0].getAsFile();
    } else {
      file = event.dataTransfer.files[0];
    }

    this.fileSelected = true;
    this.form.value.file = file;
    this.selectedFileName = file.name;

    this.upload();
  }

  public dragOverHandler(event) {
    this.dropzoneHover = true;
    event.preventDefault();
  }

  public dragLeaveHandler() {
    this.dropzoneHover = false;
    // tslint:disable-next-line: deprecation
    event.preventDefault();
  }

  public upload() {
    const file = this.form.value.file;

    this.uploadHttpService.uploadFileWithProgress(file,
      `${GeneralConstants.API_PREFIX}${this.uploadUrl}`).subscribe(
        (event) => {
          if (event.type === HttpEventType.Response) {
            this.uploadStatus = UploadStatus.SUCCES;
            this.fileUploaded.emit(event.body);
          } else {
            this.uploadStatus = UploadStatus.PROCESSING;
          }
        },
        (error) => {
          this.selectedFileName = null;
          this.fileUploadError.emit();
          this.uploadStatus = UploadStatus.ERROR;
        }
      );
  }


}
