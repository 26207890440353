import { SelectionModel } from '@angular/cdk/collections';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { Application } from 'src/app/models/application';
import { Organisation } from 'src/app/models/organisation';
import { MessageService } from 'src/app/services/message.service';
import { EditExpireDateBatchJobComponent } from '../edit-expire-date-batch-job/edit-expire-date-batch-job.component';

@Component({
  selector: 'app-organisations-multi-select-toolbar',
  templateUrl: './organisations-multi-select-toolbar.component.html',
  styleUrls: ['./organisations-multi-select-toolbar.component.scss']
})
export class OrganisationsMultiSelectToolbarComponent {

  @Input() public selection: SelectionModel<Organisation>;
  @Input() public multiSelectActive: boolean;
  @Input() public showSelectedActive: boolean;
  @Input() public applications: Application[];
  @Input() public selectedApplicationId: string;

  @Output() public multiSelectActiveChange = new EventEmitter();
  @Output() public showSelectedActiveChange = new EventEmitter();
  @Output() public refreshSelectedNeeded = new EventEmitter();

  @ViewChild('showSelectedActiveButton') showSelectedActiveButton: MatButton;

  public maxColumns: number = 0;

  constructor(
    public dialog: MatDialog,
    public messageService: MessageService,
    breakpointObserver: BreakpointObserver) {

    breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe(_state => {
        this.maxColumns = breakpointObserver.isMatched(Breakpoints.XSmall) ? 2
          : (breakpointObserver.isMatched(Breakpoints.Small) ? 3 : 0);
      });
  }

  public onMultiSelectActiveChange(event: MatButtonToggleChange) {
    this.multiSelectActiveChange.emit(event.source && event.source.checked);
    if (event.source.checked === false) {
      this.showSelectedActive = false;
    }
  }

  public onShowSelectedActiveChange(event: MatButtonToggleChange) {
    this.showSelectedActiveChange.emit(event.source && event.source.checked);
  }

  public onMultiChangeExpireDateClick() {
    const application = this.applications.find(app => app.id === this.selectedApplicationId);
    const organisationIds = this.selection.selected.map(organisation => organisation.id);
    EditExpireDateBatchJobComponent.open(this.dialog, application, organisationIds);
  }
}
