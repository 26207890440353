import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorMessageComponent } from 'src/app/components/shared/messages/http-error-message/http-error-message.component';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private snackBar: MatSnackBar) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        console.log(err.statusText + ' - ' + err.message);
        this.snackBar.openFromComponent(HttpErrorMessageComponent, {
          data: { request, errorResponse: err },
          duration: 0
        });
      }
      return throwError(err);
    }));
  }
}
