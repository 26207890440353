<mat-card>
  <mat-card-header>
    <mat-card-title>{{ 'ORGANISATIONS.MANAGEMENT.INFOTAB.TITLE' | translate }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <!-- Name -->
    <mat-form-field>
      <mat-label>{{ 'ORGANISATIONS.MANAGEMENT.INFOTAB.NAME' | translate }}</mat-label>
      <input matInput minlength="3" [formControl]="nameControl" (blur)="save()">
    </mat-form-field>
    <div class="error" *ngIf="nameControl.invalid && (nameControl.dirty || nameControl.touched)">
      {{ 'ORGANISATIONS.MANAGEMENT.INFOTAB.ERROR.NAME_INVALID' | translate }}
    </div>

    <!-- DomainFilter -->
    <mat-form-field>
      <mat-label>{{ 'ORGANISATIONS.MANAGEMENT.INFOTAB.DOMAIN' | translate }}</mat-label>
      <input matInput [formControl]="domainFilterControl" (blur)="save()">
    </mat-form-field>
    <div class="error" *ngIf="domainFilterControl.invalid && (domainFilterControl.dirty || domainFilterControl.touched)">
      {{ 'ORGANISATIONS.MANAGEMENT.INFOTAB.ERROR.DOMAIN_INVALID' | translate }}
    </div>

  </mat-card-content>
</mat-card>
