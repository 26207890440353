import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppContext } from 'src/app/helpers/app-context';
import { AccountsRole } from 'src/app/models/accounts-role';
import { Application } from 'src/app/models/application';
import { Organisation } from 'src/app/models/organisation';
import { ApplicationHttpService } from 'src/app/services/http/application-http-service';
import { OrganisationsHttpService } from 'src/app/services/http/organisations-http.service';
import { MessageService } from 'src/app/services/message.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { SubscriptionDialogData } from './subscriptions/subscription-dialog/subscription-dialog-data';
import { SubscriptionDialogType } from './subscriptions/subscription-dialog/subscription-dialog-type';
import { SubscriptionDialogComponent } from './subscriptions/subscription-dialog/subscription-dialog.component';
import { UserDialogData } from './users/user-dialog/user-dialog-data';
import { UserDialogType } from './users/user-dialog/user-dialog-type';
import { UserDialogComponent } from './users/user-dialog/user-dialog.component';

@Component({
  selector: 'app-organisation-management',
  templateUrl: './organisation-management.component.html',
  styleUrls: ['./organisation-management.component.scss']
})
export class OrganisationManagementComponent implements OnInit {

  public animatingTabs = false;

  public organisationId: string;
  public organisation: Organisation;

  public currentUserHasSupportRole = false;

  constructor(
    private route: ActivatedRoute,
    private organisationsHttpService: OrganisationsHttpService,
    private applicationHttpService: ApplicationHttpService,
    private translateService: TranslateService,
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private dialog: MatDialog,
    private appContext: AppContext
  ) {
    this.currentUserHasSupportRole = (this.appContext.user.accountsRole === AccountsRole.SUPPORT);
  }

  public ngOnInit(): void {
    this.route.params.subscribe((params: { id: string; }) => {
      this.organisationId = params.id;
      this.fetchOrganisation();
    });
  }

  public onAddUserClick() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogData: UserDialogData = {
      user: null,
      organisation: this.organisation,
      type: UserDialogType.ADD
    };
    dialogConfig.data = dialogData;
    dialogConfig.width = '600px';
    const dialogRef = this.dialog.open(UserDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fetchOrganisation();
      }
    });
  }

  public onAddLicenseClick() {
    this.applicationHttpService.getApplications()
      .pipe(this.spinnerService.register())
      .subscribe((applications: Application[]) => {
        const applicationIdsAlreadyLicensed: string[] = this.organisation.subscriptions.map(sub => sub.application.id);
        const availableApplications: Application[] = applications.filter(application =>
          !applicationIdsAlreadyLicensed.includes(application.id));
        if (availableApplications.length > 0) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          const dialogData: SubscriptionDialogData = {
            availableApplications,
            subscription: null,
            organisation: this.organisation,
            type: SubscriptionDialogType.ADD
          };
          dialogConfig.data = dialogData;
          dialogConfig.width = '600px';
          const dialogRef = this.dialog.open(SubscriptionDialogComponent, dialogConfig);
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.fetchOrganisation();
            }
          });
        } else {
          this.messageService.showInfoSnackBar(
            this.translateService.instant('SUBSCRIPTIONS.NO_UNLICENSED_APPLICATIONS_MESSAGE'));
        }
      });
  }

  public refresh() {
    this.fetchOrganisation();
  }

  private fetchOrganisation() {
    this.organisationsHttpService.getOrganisation(this.organisationId)
      .pipe(this.spinnerService.register())
      .subscribe(organisation => this.organisation = organisation);
  }

}
