import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Organisation } from 'src/app/models/organisation';
import { OrganisationsHttpService } from 'src/app/services/http/organisations-http.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-organisation-add-dialog',
  templateUrl: './organisation-add-dialog.component.html',
  styleUrls: ['./organisation-add-dialog.component.scss']
})
export class OrganisationAddDialogComponent {

  public organisationToAdd: Organisation;

  public form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<OrganisationAddDialogComponent>,
    private spinnerService: SpinnerService,
    private organisationsHttpService: OrganisationsHttpService,
    @Inject(MAT_DIALOG_DATA) organisation: Organisation
  ) {
    this.organisationToAdd = organisation;

    this.form = new FormGroup({
      name: new FormControl(''),
      domainFilter: new FormControl('')
    });
  }

  public close() {
    this.dialogRef.close(false);
  }

  public save() {
    this.fillOrganisationWithFormControls();
    this.organisationsHttpService.addOrganisation(this.organisationToAdd)
      .pipe(this.spinnerService.register())
      .subscribe(
        _result => this.dialogRef.close(true),
        _error => this.dialogRef.close(true)
      );
  }

  private fillOrganisationWithFormControls() {
    this.organisationToAdd.name = this.form.controls.name.value;
    this.organisationToAdd.domainFilter = this.form.controls.domainFilter.value;
  }

}
