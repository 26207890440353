<app-main-page-layout [pageLabel]="'GLOBAL.ORGANISATIONS' | translate" [footer]="true">

  <div class="container" [class.less-spacing]="lessSpacing">

    <app-filter class="filterbox" [filterItems]="filterItems" (filterChange)="onFilterChange()"
                [applications]="applications" [filterIsDisabled]="showSelectedActive" [filterExpireDate]="true"
                [filterApplication]="true" [filterSubscriptionType]="true" [sortSetup]="sortSetup" [syncedMatSort]="matSort"
                (sortChange)="onSortChange()">
    </app-filter>

    <app-organisations-multi-select-toolbar [selection]="selection" (refreshSelectedNeeded)="onRefreshSelectedNeeded()"
                                            [multiSelectActive]="multiSelectActive" (multiSelectActiveChange)="onMultiSelectActiveChange($event)"
                                            [showSelectedActive]="showSelectedActive" (showSelectedActiveChange)="onShowSelectedActiveChange($event)"
                                            [applications]="applications" [selectedApplicationId]="selectedApplicationId" class="multibox">
    </app-organisations-multi-select-toolbar>

    <table mat-table [dataSource]="dataSource" matSort #matSort="matSort" [matSortDisableClear]="true">

      <ng-container matColumnDef="select">
        <th mat-header-cell [hidden]="!multiSelectActive" *matHeaderCellDef>
          <mat-checkbox (change)="$event ? onSelectFullPageClick() : null"
                        [checked]="selection.hasValue() && isFullPageSelected()"
                        [indeterminate]="selection.hasValue() && !isFullPageSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell [hidden]="!multiSelectActive" *matCellDef="let organisation">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event.checked ? selection.select(organisation) : selection.deselect(organisation)"
                        [checked]="selection.isSelected(organisation)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ORGANISATIONS.LABEL_NAME' | translate }}</th>
        <td mat-cell *matCellDef="let organisation">
          <div class="avatar-and-name">
            <a [routerLink]="['/organisations', organisation.id]">
              <div class="avatar" style.backgroundImage="url({{ getOrganisationImageUrl(organisation.id) }})"></div>
            </a>
            <div><a [routerLink]="['/organisations', organisation.id]">{{ organisation.name }}</a></div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="applications">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ORGANISATIONS.LABEL_LICENSES' | translate }}</th>
        <td mat-cell *matCellDef="let organisation">
          <ng-container *ngFor="let subscription of organisation.subscriptions">
            <ng-container *ngIf="subscription.type !== 'CORPORATE'; else corporateApplicationIcon">
              <div class="application-icon" style.backgroundImage="url({{ subscription.application.avatar }})"
                   [matBadge]="subscription.maxNumberOfRegistrations"
                   matTooltip="{{ subscription.application.name }} ({{'ORGANISATIONS.LABEL_MAX_REGISTRATIONS' | translate }}: {{ subscription.maxNumberOfRegistrations }})"
                   [class.expired]="subscription.expired">
              </div>
            </ng-container>
            <ng-template #corporateApplicationIcon>
              <div class="application-icon" style.backgroundImage="url({{ subscription.application.avatar }})"
                   matTooltip="{{ 'ORGANISATIONS.TOOLTIP_CORPORATE_LICENSE' | translate:{applicationName: subscription.application.name} }}">
              </div>
            </ng-template>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="domainFilter">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ORGANISATIONS.LABEL_DOMAIN_FILTER' | translate }}</th>
        <td mat-cell *matCellDef="let organisation">{{ organisation.domainFilter }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let organisation">
          <a mat-icon-button [routerLink]="['/organisations', organisation.id]"
             matTooltip="{{ 'TABLE.LABEL_ACTION_CONFIG' | translate }}">
            <mat-icon>build</mat-icon>
          </a>
          <button mat-icon-button matTooltip="{{ 'TABLE.LABEL_ACTION_DELETE' | translate }}"
                  (click)="onDeleteOrganisationClick(organisation)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [length]="totalCount" [pageIndex]="pageIndex" [pageSize]="pageSize"
                   [pageSizeOptions]="[25, 100, 250, 500]" *ngIf="totalCount > 0" (page)="paginate($event)" [showFirstLastButtons]="true">
    </mat-paginator>

  </div>

  <button footerFloater mat-fab class="add-button" (click)="onAddOrganisationClick()" color="accent">
    <mat-icon>add</mat-icon>
  </button>

  <app-page-spinner></app-page-spinner>

</app-main-page-layout>
