export enum UserFriendlyExceptionType {
    USER_EMAIL_EXISTS = 'USER_EMAIL_EXISTS',
    DATA_PACKAGE_PARTLY_EXISTS = 'DATA_PACKAGE_PARTLY_EXISTS',
    FILE_EXTENSION_NOT_SUPPORTED = 'FILE_EXTENSION_NOT_SUPPORTED',
    IMAGE_IS_TOO_LARGE = 'IMAGE_IS_TOO_LARGE',
    WORKERQUEUE_ITEM_NO_LONGER_WAITING = 'WORKERQUEUE_ITEM_NO_LONGER_WAITING',
    WORKSPACE_HAS_MORE_THAN_ONE_REMARK_SET = 'WORKSPACE_HAS_MORE_THAN_ONE_REMARK_SET',
    WORKERQUEUE_ITEM_NOT_FOUND = 'WORKERQUEUE_ITEM_NOT_FOUND',
    WORKERQUEUE_ITEM_CANNOT_BE_MOVED = 'WORKERQUEUE_ITEM_CANNOT_BE_MOVED',
    WORKERQUEUE_ITEM_NOT_IN_PROGRESS = 'WORKERQUEUE_ITEM_NOT_IN_PROGRESS',
    WORKERQUEUE_ITEM_NOT_FAILED = 'WORKERQUEUE_ITEM_NOT_FAILED'
}
