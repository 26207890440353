import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {

  constructor(private translateService: TranslateService) {
    super();
    this.initMatPaginatorIntlMembers();

    this.translateService.onLangChange.subscribe(() => {
      this.initMatPaginatorIntlMembers();
      this.changes.next();
    });
  }

  private initMatPaginatorIntlMembers() {
    this.itemsPerPageLabel = this.translateService.instant('PAGINATOR.ITEMS_PER_PAGE');
    this.nextPageLabel = this.translateService.instant('PAGINATOR.NEXT');
    this.previousPageLabel = this.translateService.instant('PAGINATOR.PREVIOUS');
    this.lastPageLabel = this.translateService.instant('PAGINATOR.LAST_PAGE');
    this.firstPageLabel = this.translateService.instant('PAGINATOR.LAST_PAGE');

    this.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return '0 - 0 / 0 ';
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' / ' + length;
    };
  }

}
