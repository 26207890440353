import { Component, ElementRef, EventEmitter, HostListener, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { GeneralConstants } from 'src/app/utils/general-constants';
import { MainPageLayoutAnimations } from './main-page-layout-animations';

/**
 * USAGE INSTRUCTIONS
 * Frames the page between an optional header and footer. Place the content of your page inbetween the component tags.
 *
 * By default, the content will automatically vertically scroll if it does not fit in the remaining space under the header.
 * The footer will not be visible until you scroll all the way down. If the content needs no scrolling, the footer is still displayed
 * sticky at the bottom of the page.
 *
 * If the content is a [mat-tab-group], this default scrolling behaviour does not work well. To make sure the tab group headers remain
 * always visible at the top:
 * - Apply position: absolute (0, 0, 0, 0) to the mat-tab-group.
 * - Set footerFixed = true; this fixes the footer at the bottom of the page regardless of scrolling behaviour.
 * Absolute positioning makes sure the top level content will never scroll, so that scrolling is now handled by each
 * individual mat-tab-body.
 *
 * If one of the first childs of your content has the footerFloater attribute, it will float at the bottom of the page but add
 * additional spacing when the footer scrolls into view.
 */
@Component({
  selector: 'app-main-page-layout',
  templateUrl: './main-page-layout.component.html',
  styleUrls: ['./main-page-layout.component.scss'],
  animations: MainPageLayoutAnimations.ANIMATIONS
})
export class MainPageLayoutComponent {

  @Input() public header: boolean = true;
  @Input() public toolbar: TemplateRef<any> = null;
  @Input() public toolbarVisible: boolean = false;
  @Input() public footer: boolean = false;
  @Input() public footerFixed: boolean = false;
  @Input() public pageLabel: string;
  @Input() public statusIcon: string = null;

  @Output() public contentHeightChanged = new EventEmitter<void>();

  public footerFloaterBottomMargin: number = 0;

  @ViewChild('pageContent', { static: false }) private pageContentDiv: ElementRef;

  @HostListener('window:resize')
  public onWindowResize() {
    this.refreshFooterFloaterPosition();
  }

  @HostListener('window:scroll')
  public onWindowScroll() {
    this.refreshFooterFloaterPosition();
  }

  public onPageContentScroll() {
    this.refreshFooterFloaterPosition();
  }

  public onHeightChange() {
    this.contentHeightChanged.emit();
  }

  private refreshFooterFloaterPosition() {
    if (this.footer) {
      const distanceFromBottom = this.pageContentDiv.nativeElement.scrollHeight
        - this.pageContentDiv.nativeElement.clientHeight
        - this.pageContentDiv.nativeElement.scrollTop;
      this.footerFloaterBottomMargin = Math.max(0, GeneralConstants.FOOTER_HEIGHT - distanceFromBottom);
    }
  }

}
