import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AccountsRole } from 'src/app/models/accounts-role';
import { FilterItem } from 'src/app/models/filter-item';
import { Organisation } from 'src/app/models/organisation';
import { SortDirectionType } from 'src/app/models/sort-item';
import { User } from 'src/app/models/user';
import { UsersHttpService } from 'src/app/services/http/users-http.service';
import { MessageService } from 'src/app/services/message.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { LocalStorageConstants } from 'src/app/utils/local-storage-constants';
import { UserUtils } from 'src/app/utils/user-utils';
import { SortSetup } from '../../shared/filter/sort-setup';
import { UserDialogData } from './user-dialog/user-dialog-data';
import { UserDialogType } from './user-dialog/user-dialog-type';
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import { UserRecentLoginsDialogData } from './user-recent-logins-dialog/user-recent-logins-dialog-data';
import { UserRecentLoginsDialogComponent } from './user-recent-logins-dialog/user-recent-logins-dialog.component';

const DEFAULT_COLUMNS = ['name', 'email', 'lastLogin', 'registrations', 'admin', 'actions'] as const;
type TableColumn = typeof DEFAULT_COLUMNS[number];

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements AfterViewInit {

  private static readonly DEFAULT_COLUMNS = [...DEFAULT_COLUMNS];
  private static readonly COMPACT_COLUMNS = UsersComponent.DEFAULT_COLUMNS;

  @ViewChild(MatPaginator) private paginator: MatPaginator;
  @ViewChild(MatSort) private sort: MatSort;

  private _organisation: Organisation;

  get organisation() {
    return this._organisation;
  }

  @Input() set organisation(value: Organisation) {
    if (value) {
      this._organisation = value;
      this.dataSource = new MatTableDataSource(value.users);
      this.dataSource.sortingDataAccessor = (user: User, sortHeaderId: TableColumn) => {
        if (sortHeaderId === 'registrations') {
          return user.registrations.length;
        } else if (sortHeaderId === 'admin') {
          return user.accountsRole === AccountsRole.ORGANISATION_MANAGER ? 1 : 0;
        } else {
          return String(user[sortHeaderId]);
        }
      };
      this.initDataSourceFeatures();
    }
  }
  @Output() refresh = new EventEmitter();

  public displayedColumns: string[] = UsersComponent.DEFAULT_COLUMNS;
  public dataSource: MatTableDataSource<User> = new MatTableDataSource([]);

  public sortSetup = SortSetup
    .create({ name: 'NAME', email: 'EMAIL', registrations: 'REGISTRATIONS', admin: 'ADMIN', lastLogin: 'LAST_LOGIN' } as { [K in TableColumn]: string })
    .select('name' as TableColumn, SortDirectionType.ASCENDING)
    .selectFromStorage(LocalStorageConstants.SORT_MODE_USERS);

  public filterItems: FilterItem[] = [];

  public lessSpacing: boolean = false;

  constructor(
    breakpointObserver: BreakpointObserver,
    private usersHttpService: UsersHttpService,
    private dialog: MatDialog,
    private spinnerService: SpinnerService,
    private messageService: MessageService
  ) {
    breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium]).subscribe(state =>
      this.lessSpacing = state.matches);
    breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(state =>
      this.displayedColumns = state.matches ? UsersComponent.COMPACT_COLUMNS : UsersComponent.DEFAULT_COLUMNS);
  }

  public ngAfterViewInit() {
    this.initDataSourceFeatures();
  }

  private initDataSourceFeatures() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public onFilterChange() {
    const filterItem = this.filterItems.find(item => item.key === 'filterText');
    this.dataSource.filter = filterItem ? String(filterItem.value) : null;
  }

  public onSortChange() {
    this.sortSetup.saveToStorage(LocalStorageConstants.SORT_MODE_USERS);
  }

  public onEditUserClick(userToEdit: User): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogData: UserDialogData = {
      user: userToEdit,
      organisation: this.organisation,
      type: UserDialogType.EDIT
    };
    dialogConfig.data = dialogData;
    dialogConfig.width = '600px';
    const dialogRef = this.dialog.open(UserDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(ok => ok && this.refresh.emit());
  }

  public onRemoveUserClick(userToRemove: User): void {
    this.messageService.showOkCancelDialog({
      titleLangKey: 'USERS.LABEL_ACTION_DELETE',
      messageLangKey: userToRemove.name ? 'USERS.DELETE_CONFIRM_WITH_NAME' : 'USERS.DELETE_CONFIRM',
      langParams: { email: userToRemove.email, name: userToRemove.name }
    }, null, () => {
      this.usersHttpService.deleteUser(userToRemove.id)
        .pipe(this.spinnerService.register())
        .subscribe(() => {
          this.refresh.emit();
        });
    });
  }

  public onShowRecentLoginsClick(user: User) {
    this.usersHttpService.getRecentUserLogins(user.id)
      .pipe(this.spinnerService.register())
      .subscribe(recentUserLogin => {
        const dialogConfig = new MatDialogConfig<UserRecentLoginsDialogData>();
        dialogConfig.disableClose = false;
        dialogConfig.data = { recentUserLogin };
        dialogConfig.width = '600px';
        this.dialog.open(UserRecentLoginsDialogComponent, dialogConfig);
      });
  }

  public canRemoveUser(user: User): boolean {
    const userIsOnlyOrganisationManager = this.isOrganisationManager(user)
      && this.getNumberOfOrganisationManagers() === 1;
    return (user.registrations.length === 0 &&
      (!userIsOnlyOrganisationManager || this.organisation.users.length === 1));
  }

  public getUserMediaImageUrl(user: User) {
    return UserUtils.getUserMediaImageUrl(user);
  }

  public isOrganisationManager(user: User) {
    return (user.accountsRole === AccountsRole.ORGANISATION_MANAGER || user.accountsRole === AccountsRole.SUPPORT);
  }

  private getNumberOfOrganisationManagers(): number {
    return this.organisation.users.filter(user => this.isOrganisationManager(user)).length;
  }
}
