import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { AppContext } from '../helpers/app-context';
import { AccountsRole } from '../models/accounts-role';
import { AuthorisationService } from '../services/authorisation.service';

@Injectable({ providedIn: 'root' })
export class AuthThenRedirectGuard implements CanActivate {

  constructor(private authorisationService: AuthorisationService, private router: Router, private appContext: AppContext) {
  }

  public canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authorisationService.validateAuthAndLoginIfNeeded(state.url).pipe(
      map(valid => {
        if (valid) {
          return this.createRedirect();
        } else {
          return false;
        }
      })
    );
  }

  private createRedirect() {
    if (this.appContext.user.accountsRole === AccountsRole.SUPPORT) {
      return this.router.createUrlTree(['/organisations']);
    } else if (this.appContext.user.accountsRole === AccountsRole.ORGANISATION_MANAGER) {
      return this.router.createUrlTree(['/organisations', this.appContext.user.organisationId]);
    } else {
      return this.router.createUrlTree(['/account']);
    }
  }

}
