import { SortDirectionType, SortItem } from 'src/app/models/sort-item';

export class SortSetup {

  public items: SortItem[] = [];

  public static create(columnsToParams: { [columnId: string]: string }): SortSetup {
    const sortSetup = new SortSetup();
    Object.keys(columnsToParams).forEach(columnId => sortSetup.items.push(...SortItem.createAscDesc(columnId, columnsToParams[columnId])));
    return sortSetup;
  }

  public select(columnId: string, direction: SortDirectionType): SortSetup {
    this.items.forEach(sortItem => sortItem.selected = (sortItem.columnId === columnId && sortItem.direction === direction));
    return this;
  }

  public selectFromStorage(storageKey: string) {
    if (this.items.length > 0) {
      try {
        const storedSort: [string, SortDirectionType] = JSON.parse(localStorage.getItem(storageKey));
        if (storedSort && Array.isArray(storedSort) && storedSort.length === 2 && storedSort[0] && storedSort[1]) {
          return this.select(storedSort[0], storedSort[1]);
        }
      } catch (e) { }
    }
    return this;
  }

  public saveToStorage(storageKey: string) {
    if (this.items.length > 0) {
      const sortItemToStore = this.getSelected();
      if (sortItemToStore) {
        localStorage.setItem(storageKey, JSON.stringify([sortItemToStore.columnId, sortItemToStore.direction]));
      } else {
        localStorage.removeItem(storageKey);
      }
    }
  }

  public getSelected(): SortItem {
    return this.items.find(sortItem => sortItem.selected);
  }

}
