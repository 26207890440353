<h2 mat-dialog-title>{{ 'USERS.' + type + '_DIALOG.TITLE' | translate }}</h2>

<mat-dialog-content class="mat-typography">

  <form class="form-container" [formGroup]="form">
    
    <!-- Name -->
    <mat-form-field>
      <mat-label>{{ 'USERS.DIALOG.NAME' | translate }}</mat-label>
      <input matInput type="text" formControlName="name">
    </mat-form-field>

    <!-- Email -->
    <mat-form-field>
      <mat-label>{{ 'USERS.DIALOG.EMAIL' | translate }}</mat-label>
      <input required matInput type="email" formControlName="email" [readonly]="type === 'EDIT'">
      <mat-error *ngIf="form.controls.email.invalid">{{ getEmailErrorMessage() }}</mat-error>
    </mat-form-field>

    <!-- TODO: Avatar -->

    <!-- Make Organisation Manager -->
    <mat-slide-toggle formControlName="organisationManager" color="primary"
      matTooltip="{{ 'USERS.DIALOG.ORGANISATION_MANAGER_INFO' | translate }}">
      {{ 'USERS.DIALOG.MAKE_ORGANISATION_MANAGER' | translate }}
    </mat-slide-toggle>

  </form>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="close()">{{ 'GLOBAL.CANCEL' | translate }}</button>
  <button mat-button color="primary" (click)="save()" [disabled]="form.invalid || form.pending || !isFormChanged()">
    {{ ('GLOBAL.' + type) | translate }}
  </button>
</mat-dialog-actions>