import { Application } from './application';
import { SubscriptionType } from './subscription-type';

export class ApplicationSubscription {
  application: Application;
  maxNumberOfRegistrations: number;
  expireDate: Date;
  expired: boolean;
  type: SubscriptionType;
  numberOfRegistrations: number;
}
