import { Component, Input, OnChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Organisation } from 'src/app/models/organisation';
import { SubscriptionType } from 'src/app/models/subscription-type';
import { OrganisationsHttpService } from 'src/app/services/http/organisations-http.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnChanges {

  @Input() public organisation: Organisation;

  public nameControl: FormControl;
  public domainFilterControl: FormControl;

  constructor(private spinnerService: SpinnerService, private organisationsHttpService: OrganisationsHttpService) { }

  public ngOnChanges() {
    this.nameControl = new FormControl(this.organisation?.name, Validators.minLength(3));
    this.domainFilterControl = new FormControl(this.organisation?.domainFilter, Validators.pattern(/^([a-z0-9.-]+\.+[a-z]+)+$|^$/));
    if (this.isDomainFilterControlDisabled()) {
      this.domainFilterControl.disable();
    }
  }

  public save() {
    if (this.nameControl.valid && (this.domainFilterControl.valid || this.domainFilterControl.disabled)) {
      const adjustedOrganisation = this.setOrganisation(this.organisation);
      this.organisationsHttpService.updateOrganisation(adjustedOrganisation)
        .pipe(this.spinnerService.register())
        .subscribe(
          _result => console.log("updated Organisation"),
          _error => console.log("updated Organisation")
        );
    }
  }

  private setOrganisation(organisation: Organisation) {
    organisation.name = this.nameControl.value;
    if (organisation?.users.length === 0) {
      organisation.domainFilter = this.domainFilterControl.value;
    }
    return organisation;
  }

  public isDomainFilterControlDisabled(): boolean {
    return (!this.organisation || this.organisation.users.length > 0 || this.getNumberOfCorporateLicenses() > 0);
  }

  private getNumberOfCorporateLicenses(): number {
    return this.organisation.subscriptions.filter(sub => sub.type === SubscriptionType.CORPORATE).length;
  }
}
