<ng-container *ngIf="registeredUsers?.length > 0; else initialRegistrations">
  <table mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'USERS.LABEL_NAME' | translate }}</th>
      <td mat-cell *matCellDef="let user">
        <div class="avatar-and-name">
          <div class="avatar" style.backgroundImage="url({{ getUserMediaImageUrl(user) }})"></div>
          <div>{{ user.name || '&mdash;' }}</div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'USERS.LABEL_EMAIL' | translate }}</th>
      <td mat-cell *matCellDef="let user">{{ user.email }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let user">
        <button mat-icon-button matTooltip="{{ 'REGISTRATIONS.LABEL_DELETE' | translate }}"
          (click)="onRemoveRegistrationClicked(user)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div class="add-registration-row">
    <button (click)="onAddRegistrationClicked()" mat-button color="primary"
      [disabled]="subscription.numberOfRegistrations >= subscription.maxNumberOfRegistrations">
      <mat-icon>add_circle_outline</mat-icon>
      {{ 'REGISTRATIONS.BUTTON_ADD' | translate }}
    </button>
  </div>
</ng-container>
<ng-template #initialRegistrations> 
  <div class="registrations-initial">
    <div>{{'REGISTRATIONS.ADD_FIRST_REGISTRATION_MESSAGE' | translate}}</div>
    <div class="first-registration-button">
      <button mat-button color="primary" [disabled]="subscription.maxNumberOfRegistrations <= 0"
        (click)="onAddRegistrationClicked()">{{'REGISTRATIONS.ADD_FIRST_REGISTRATION_BUTTON_LABEL'
        | translate}}</button>
    </div>
  </div>
</ng-template>